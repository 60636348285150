import { Box, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import useLocal from '../../store/local/hooks/useLocal';

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: 1,
    marginTop: '20px',
    fontSize: '1.5rem',
  },
  subtitle: {
    lineHeight: 1,
    marginTop: '20px',
    marginBottom: '15px',
    fontSize: '1rem',
    color: '#757575',
  },
  link: {
    color: '#757575 !important',
    textDecoration: 'underline !important',
  },
  note: {
    lineHeight: 1,
    fontSize: '0.8rem',
    color: '#989898',
  },
  alignItemsAndJustifyContent: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  largeIcon: {
    fontSize: '600%',
    color: theme.palette.primary.main,
    marginTop: '10px',
  },
}));

export default () => {
  const { text } = useLocal();
  const classes = useStyles();

  return (
    <Container maxWidth="xs">
      <Box
        className={classes.alignItemsAndJustifyContent}
        boxShadow={3}
        bgcolor="background.paper"
        marginTop={20}
        m={1}
        p={1}
      >
        <CheckIcon className={classes.largeIcon} />
        <Typography className={classes.title}>{text.success.login.title}</Typography>
        <Typography className={classes.subtitle}>
          {text.success.login.subTitlePre}
          {' '}
          <a className={classes.link} target="_blank" rel="noreferrer" href="https://rakuraku.alc.co.jp/">
            {text.success.login.subTitleLink}
          </a>
          {' '}
          {text.success.login.subTitlePost}
        </Typography>
        <Box mt={1} />
        <Typography className={classes.note}>{text.success.login.note1}</Typography>
        <Typography className={classes.note}>{text.success.login.note2}</Typography>
        <Box mb={1} />
      </Box>
    </Container>
  );
};
