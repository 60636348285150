import { Link as RouterLink } from 'react-router-dom';
import { Link, Grid, GridSize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useSearch from '../../../../../store/book/hooks/useSearch';
import ImageAssets from '../../../../../assets/index';

const useStyles = makeStyles((theme) => ({
  button: {
    border: 'none',
    background: 'none',
    outline: 'none',
    '&:hover': { cursor: 'pointer' },
    [theme.breakpoints.up('sm')]: { '&:hover': { opacity: '0.5' } },
  },
  noImgButton: {
    border: '1px solid teal',
    background: 'teal',
    color: 'white',
    outline: 'none',
    '&:hover': { cursor: 'pointer' },
    [theme.breakpoints.up('sm')]: { '&:hover': { opacity: '0.5' } },
    width: '100%',
    padding: '8px 0',
    fontWeight: 'bold',
    fontSize: '1rem',
    borderRadius: '4px',
    letterSpacing: '.3rem',
  },
  active: { opacity: '0.5' },
  gridItem: { [theme.breakpoints.down('xs')]: { padding: '0 0 8px !important' } },
}));

export interface ISubcategory {
  'title': string,
  'buttonKey': string
  'isLink': boolean
  'pageName': string | null
}

interface IProps {
  'item': ISubcategory,
  'xs': GridSize,
  'sm': GridSize
}

export default ({ item, xs, sm }: IProps) => {
  const classes = useStyles();
  const { books, filter } = useSearch();

  const cls = item.title === books.filters[0]?.filterValue
    ? `${classes.button} ${classes.active}`
    : classes.button;

  return (
    <Grid
      classes={{ item: classes.gridItem }}
      key={item.title}
      item
      xs={xs}
      sm={sm}
    >
      {item.isLink && item.pageName && (
        <Link component={RouterLink} to={item.pageName}>
          <img src={ImageAssets[item.buttonKey]} alt={item.title} />
        </Link>
      )}
      {!item.isLink && item.buttonKey && (
        <button
          className={cls}
          type="button"
          onClick={() => filter({
            filterType: 'subcategory',
            filterValue: item.title,
          })}
        >
          <img src={ImageAssets[item.buttonKey]} alt={item.title} />
        </button>
      )}
      {!item.isLink && !item.buttonKey && (
        <button
          className={classes.noImgButton}
          type="button"
          onClick={() => filter({
            filterType: 'subcategory',
            filterValue: item.title,
          })}
        >
          {item.title}
        </button>
      )}
    </Grid>
  );
};
