/* eslint-disable max-len */
export const PAGE_LOADING = 'registration/PAGE_LOADING';
export const PAGE_LOADED_SUCCESS = 'registration/PAGE_LOADED_SUCCESS';
export const PAGE_LOADED_ERROR = 'registration/PAGE_LOADED_ERROR';
export const pageLoadingStarted = () => ({ type: PAGE_LOADING });
export const pageLoadedWithSuccess = (payload: any) => ({ payload, type: PAGE_LOADED_SUCCESS });

export const FORM_INACTIVE = 'registration/FORM_INACTIVE';
export const FORM_SUBMITTING = 'registration/FORM_SUBMITTING';
export const FORM_SUBMITTED_SUCCESS = 'registration/FORM_SUBMITTED_SUCCESS';
export const FORM_SUBMITTED_ERROR = 'registration/FORM_SUBMITTED_ERROR';
export const formStateReset = () => ({ type: FORM_INACTIVE });
export const formSubmitted = () => ({ type: FORM_SUBMITTING });
export const formSubmittedWithSuccess = () => ({ type: FORM_SUBMITTED_SUCCESS });
export const formSubmittedWithError = () => ({ type: FORM_SUBMITTED_ERROR });
