/* eslint-disable no-unused-vars */
import { PageLoadingStatus } from './constants';

import {
  BOOK_LOADING,
  BOOK_LOADED_SUCCESS,
  BOOK_LOADED_ERROR,
} from './actions';

export const INITIAL_STATE = {
  data: null,
  bookLoadingState: PageLoadingStatus.LOADING,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case BOOK_LOADING:
      return {
        ...state,
        data: null,
        bookLoadingState: PageLoadingStatus.LOADING,
      };
    case BOOK_LOADED_SUCCESS:
      return {
        ...state,
        data: action.payload,
        bookLoadingState: PageLoadingStatus.LOADED_SUCCESS,
      };
    case BOOK_LOADED_ERROR:
      return {
        ...state,
        data: null,
        bookLoadingState: PageLoadingStatus.LOADED_ERROR,
      };
    default:
      return state;
  }
};
