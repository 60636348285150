import { CssBaseline, Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  base: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 0,
      margin: 0,
    },
    [theme.breakpoints.up('sm')]: { width: '750px !important' },
  },
  wrap: { overflow: 'hidden' },
}));

const StudentLayout = () => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container className={classes.base}>
        <Box className={classes.wrap}>
          <Outlet />
        </Box>
      </Container>
    </>
  );
};

export default StudentLayout;
