import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderSection from './sections/header';
import MaterialSection from './sections/material';
import PageNotFound from '../@common/components/PageNotFound';
import useFetch from '../../store/material/hooks/useFetch';
import useAuth from '../../store/auth/hooks/useAuth';
import FullpageLoader from '../@common/components/forms/FullpageLoader';

import './style.css';

export default () => {
  const { productId, subProductId } = useParams();
  const { material, bookLoading, bookLoadingError, loadDetails } = useFetch();
  const { auth: { roles } } = useAuth();

  useEffect(() => { loadDetails({ roles, productId, subProductId }); }, []);

  if (bookLoading) { return <FullpageLoader dark={false} open={bookLoading} />; }
  if (bookLoadingError) { return <PageNotFound />; }
  document.title = material ? 'ダウンロードセンター｜アルク' : 'お探しのページは見つかり';
  return (
    <>
      <HeaderSection title={material.title} description={material.description} />
      <MaterialSection materials={material.books} />
    </>
  );
};
