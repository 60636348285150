/* eslint-disable semi */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Grid, Typography, Box, Button, Popover } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useFetch from '../../../store/hooks/useFetch';
import CheckList from '../components/CheckList'
import useLocal from '../../../../../store/local/hooks/useLocal';

const ActionButton = styled(Button)(({ theme }) => ({
  maxHeight: '30px',
  textTransform: 'none',
  color: theme.palette.text.primary,
  background: theme.palette.background.default,
  outline: 'none',
  '&:hover': { background: theme.palette.background.paper },
  marginTop: theme.spacing(1),
}));

const useStyles = makeStyles({
  labelTitle: {
    lineHeight: 1,
    fontWeight: 600,
    fontSize: '0.9rem',
  },
  errorText: {
    lineHeight: 1,
    color: '#f44336',
  },
});

interface IProp {
  changeHandler: Function,
  hasError: boolean,
  errorMessage: string,
  title: string,
}
export default ({ changeHandler, hasError, errorMessage, title }: IProp) => {
  const { text } = useLocal();
  const classes = useStyles();
  const { roles, loading, fetch } = useFetch();
  const [selectedRoles, setSelectedRoles] = useState('');
  const [el, setEl] = useState<HTMLButtonElement | null>(null);
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => setEl(event.currentTarget);
  const onClose = () => setEl(null);
  const actionOpen = Boolean(el);
  const actionId = actionOpen ? 'action-popover' : undefined;

  useEffect(() => { if (loading) fetch(); }, [loading]);

  const displayRoles = (
  ) => {
    let str = '';
    if (!loading && selectedRoles !== '') {
      roles.forEach((i:any) => {
        if (selectedRoles.includes(i.id.toString())) {
          str += `${str.length > 0 ? ', ' : ''}${i.title}`;
        }
      });
    }
    return str === '' ? text.register.form.none : str;
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography className={classes.labelTitle}>
          {title}
        </Typography>
        <Box height={10} />

        <ActionButton
          variant="contained"
          size="medium"
          endIcon={<ArrowDropDownIcon />}
          disableRipple
          onClick={onClick}
        >
          <Typography variant="caption">
            {text.register.form.rolesDropDownText}
            &nbsp;
          </Typography>
          <Typography variant="caption"><b>{displayRoles()}</b></Typography>
        </ActionButton>
        {hasError && (
        <Typography
          className={classes.errorText}
          variant="caption"
          component="p"
        >
          {errorMessage}
        </Typography>
        )}
        <Popover
          id={actionId}
          open={actionOpen}
          anchorEl={el}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {loading
            ? <> </>
            : (
              <CheckList
                selected={selectedRoles}
                items={roles}
                changeHandler={(selected: any) => {
                  setSelectedRoles(selected);
                  return changeHandler(selected);
                }}
              />
            )}
        </Popover>
      </Grid>
    </>
  );
};
