import { Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentImageUrl: { [theme.breakpoints.down('xs')]: { width: '90%' } },
  contentImageUrlBox: {
    [theme.breakpoints.down('xs')]: { textAlign: 'center' },
    [theme.breakpoints.up('sm')]: { marginLeft: theme.spacing(2) },
  },
  instructions: {
    marginTop: theme.spacing(2),
    fontSize: '12px',
  },
}));

interface IProps {
  'title': string
  'label': string
  'buttonImageUrl': string
  'contentUrl': string
}

export default ({
  title,
  label,
  buttonImageUrl,
  contentUrl,
}: IProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.instructions} dangerouslySetInnerHTML={{ __html: title }} />

      {label && (
        <div className={classes.instructions} dangerouslySetInnerHTML={{ __html: label }} />
      )}

      <Box my={2} className={classes.contentImageUrlBox}>
        <Link href={contentUrl}>
          <img className={classes.contentImageUrl} src={buttonImageUrl} alt={title} />
        </Link>
      </Box>
    </>
  );
};
