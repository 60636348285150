/* eslint-disable no-unused-vars */
import {
  Typography,
  List,
  ListItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl, FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useState, useEffect } from 'react';
import { Role } from '../../../store/types';

const useStyles = makeStyles((theme) => ({
  formLabelTitle: { color: theme.palette.text.primary },
  labelTitle: {
    lineHeight: 1,
    fontWeight: 600,
  },
  list: {
    padding: 0,
    minWidth: '180px',
    width: '100%',
    bgcolor: 'background.paper',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
  },
  item: { padding: '0px 0px 0px 10px' },
}));

interface IProp {
  changeHandler: Function
  items: Role[]
  hasError?: boolean
  errorMessage?: string
  selected: string
}

export default ({
  items,
  changeHandler,
  hasError = false,
  errorMessage = '',
  selected,
}: IProp) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(hasError);
  const [roles, setRoles] = useState(selected);
  useEffect(() => { setOpen(hasError); }, [hasError]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>, id: Number,
  ) => {
    let str = roles;
    if (e.target.checked) {
      if (str) {
        str += `,${id.toString()}`;
      } else {
        str += `${id.toString()}`;
      }
    } else {
      str = str.replace(`${id.toString()},`, '');
      str = str.replace(`,${id.toString()}`, '');
      str = str.replace(id.toString(), '');
    }
    setRoles(str);
    changeHandler(str);
  };

  const handleDefaultCheck = (
    id: Number,
  ) => selected.includes(id.toString());

  return (
    <>
      <FormControl error={hasError} fullWidth>
        <FormGroup
          aria-label="page"
        >
          <List component="nav" aria-label="page" className={classes.list}>
            {items.map((i, index) => (
              <ListItem key={i.id} divider={index + 1 !== items.length} className={classes.item}>
                <FormControlLabel
                  value={i.id}
                  control={<Checkbox size="small" defaultChecked={handleDefaultCheck(i.id)} onChange={(e) => handleChange(e, i.id)} color="primary" />}
                  label={(<Typography className={classes.labelTitle} variant="caption" component="p">{i.title}</Typography>
                  )}
                />
              </ListItem>
            ))}
          </List>
        </FormGroup>
        <FormHelperText>{hasError ? errorMessage : ''}</FormHelperText>
      </FormControl>
    </>
  );
};
