import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Mutations from '../mutations';

interface UserCredential {
  accessToken: string
  email: string
  roles: string[]
}

const useAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const authUser = async (email: string, password: string) => {
    setIsLoading(true);

    try {
      const response = await Mutations.authenticate(email, password);
      response.roles.push('public');
      window.localStorage.setItem('dlc-portal-user', JSON.stringify(response));
      navigate('/login/success');
    } catch (err) {
      setError(true);
    }

    setIsLoading(false);
  };

  const setDefaultRole = () => {
    const defaultCredentials = { roles: ['public'] };
    window.localStorage.setItem('dlc-portal-user', JSON.stringify(defaultCredentials));
    return defaultCredentials;
  };

  const getCredentials = () => {
    const item = window.localStorage.getItem('dlc-portal-user');
    const userCredentials = JSON.parse(item!);

    if (!userCredentials) {
      return setDefaultRole();
    }

    return userCredentials;
  };

  const verifyUser = async () => {
    const user = getCredentials() as UserCredential;
    await Mutations.verify(user.accessToken);
  };

  const logoutUser = async () => {
    setDefaultRole();
    navigate('/');
  };

  return {
    auth: getCredentials() as UserCredential,
    isLoading,
    error,
    authUser,
    verifyUser,
    logoutUser,
  };
};

export default useAuth;
