/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export enum FormStatus {
  INACTIVE = 'inactive',
  SUBMITTING = 'submitting',
  SUBMITTED_SUCCESS = 'submitted_success',
  SUBMITTED_ERROR = 'submitted_error',
}

const BASE_API_URL = `${process.env.REACT_APP_API_URL}`;
export const REQUEST_RESET_API = `${BASE_API_URL}/reset_password`;
