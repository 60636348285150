import {
  Grid,
  Paper,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Book from './Book';
import Asset from './Asset';
import BookTitle from './BookTitle';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '2px solid #ff9f39',
    background: '#fff8ed',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: { margin: '25px 75px 25px 75px' },
    marginBottom: theme.spacing(2),
  },
  bookContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
  },
}));

interface IAsset {
  title: string
  label: string
  contentUrl: string
  buttonImageUrl: string
}

export interface IMaterial {
  productId: string
  subProductId: string
  imageUrl: string
  title: string
  headerTitle: string
  htmlData: string
  assets: IAsset[]
}

interface IProps {
  'material': IMaterial
}

export default ({ material }: IProps) => {
  const classes = useStyles();
  return (
    <>
      {material.htmlData?.length > 0
        && <div dangerouslySetInnerHTML={{ __html: material.htmlData }} />}

      {material.htmlData === null
        && (
          <>
            {material.title && <BookTitle text={material.title} />}
            <Paper className={classes.paper} variant="outlined" square>
              <Box p={4}>
                <Grid
                  container
                  spacing={1}
                  justify="space-between"
                >
                  <Book item={{ title: material.title, imageUrl: material.imageUrl }} />

                  <Grid item xs={12} sm={7}>
                    <div dangerouslySetInnerHTML={{ __html: material.headerTitle }} />

                    <Box mt={3}>
                      {material.assets.map((i) => (
                        <Asset
                          key={i.title}
                          label={i.label}
                          title={i.title}
                          buttonImageUrl={i.buttonImageUrl}
                          contentUrl={i.contentUrl}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </>
        )}
    </>
  );
};
