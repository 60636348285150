/* eslint-disable no-restricted-globals */
/* eslint-disable no-mixed-operators */
import { useEffect, useLayoutEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Link } from '@material-ui/core';
import useFetch from '../../store/material/hooks/useFetch';
import useAuth from '../../store/auth/hooks/useAuth';
import FullpageLoader from '../@common/components/forms/FullpageLoader';

import './style.css';

export default () => {
  const { productId, subProductId } = useParams();
  const { bookLoading, loadDetails } = useFetch();
  const { auth: { roles } } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const subId = subProductId || '';

  useEffect(() => { loadDetails({ roles, productId, subProductId }); }, []);

  useLayoutEffect(() => {
    const SP_SCHEME = `alcoapp://webview?URL=${process.env.REACT_APP_PORTAL_URL}/dl/${productId}/${subId}`;
    const userAgent = navigator.userAgent.toLowerCase();
    const isDeepLink = pathname.substring(0, 9) === '/dl/alco/';
    const isMobileUserAgent = userAgent.search(/iphone|ipad|ipod|android|opera.*mini|windows.*phone/) > -1 || userAgent.indexOf('macintosh') > -1;
    const hasTouchEndHandler = 'ontouchend' in document;
    const shouldOpenApp = isMobileUserAgent && hasTouchEndHandler && isDeepLink;

    if (shouldOpenApp) {
      setTimeout(() => { location.href = SP_SCHEME; }, 500);
    } else if (isDeepLink) {
      navigate(`/dl/${productId}/${subId}`);
    }
  });

  if (bookLoading) { return <FullpageLoader dark={false} open={bookLoading} />; }

  return <Link href={`${process.env.REACT_APP_PORTAL_URL}/dl/${productId}/${subId}`}>自動遷移しない場合コチラをクリック</Link>;
};
