/* eslint no-nested-ternary: */
import { useState } from 'react';
import { Grid, Typography, TextField, InputAdornment, IconButton } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import VisibilityOn from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles({
  labelTitle: {
    lineHeight: 1,
    fontWeight: 600,
    fontSize: '0.9rem',
  },
});

interface IProp {
  onChange: Function,
  label: string,
  type: string,
  autoFocus?: boolean
  placeholder?: string,
  error?: boolean
  helperText?: string
  password?: boolean,
}
export default ({ onChange, label, type, autoFocus = false, placeholder = '', error = false, helperText = '', password = false }: IProp) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  return (
    <>

      <Grid item xs={12}>
        <Typography className={classes.labelTitle}>
          {label}
        </Typography>
        <TextField
          InputProps={password ? {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOn /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          } : {}}
          error={error}
          margin="dense"
          variant="outlined"
          placeholder={placeholder}
          type={password ? showPassword ? 'text' : 'password' : type}
          helperText={error ? helperText : ''}
          autoFocus={autoFocus}
          fullWidth
          onChange={(e) => onChange(e.target.value)}
        />
      </Grid>

    </>
  );
};
