/* eslint-disable no-unused-vars */
import { useStateValue } from '../../index';
import { setSearchResult, clearSearchResult } from '../actions';

const useSearch = () => {
  const [state, dispatch] = useStateValue();
  const { books } = state;
  const { packages } = books;

  const FILTER_BY_SUBCATEGORY = 'subcategory';

  interface IMaterial {
    'pageName': string
    'productId': string
    'subProductId': string | null
    'categoryTitle': string
    'subcategoryTitle': string
    'headerImageKey': string
    'bookTitle': string
    'bookImageUrl': string
    'needAuth': number
    'password': string | null
    'passwordId': string | null
    'metadata': string
    'headerNotes': string | null
    'downloadNotes': string | null
  }

  interface ISearchResultItem {
    'type': string
    'productId': string
    'subProductId': string | null
    'title': string
    'imageUrl': string
    'headerNotes': string | null
  }

  interface IFilter {
    filterType: string
    filterValue: string
  }

  interface ISearchResult {
    filtered: IMaterial[]
    filterType: string
    filterValue: string
  }

  const mapRawSearchToSearchResults = (rawSearch: IMaterial[]) => {
    const searchResults: ISearchResultItem[] = [];
    const groupedSearchResults: any = [];

    rawSearch?.forEach((item) => {
      const subcategory = groupedSearchResults.find((i: any) => i.subcategoryTitle === item.subcategoryTitle);

      if (!subcategory) {
        groupedSearchResults.push({
          subcategoryTitle: item.subcategoryTitle,
          categoryTitle: item.categoryTitle,
          headerImageKey: item.headerImageKey,
          headerNotes: item.headerNotes,
          books: [{
            type: 'BOOK',
            productId: item.productId,
            subProductId: item.subProductId,
            title: item.bookTitle,
            imageUrl: item.bookImageUrl,
            needAuth: item.needAuth === 1,
            password: item.password,
            passwordId: item.passwordId,
            downloadNotes: item.downloadNotes,
          }],
        });
      } else {
        subcategory.books.push({
          type: 'BOOK',
          productId: item.productId,
          subProductId: item.subProductId,
          title: item.bookTitle,
          imageUrl: item.bookImageUrl,
          needAuth: item.needAuth === 1,
          password: item.password,
          passwordId: item.passwordId,
          downloadNotes: item.downloadNotes,
        });
      }
    });

    groupedSearchResults.forEach((s: any) => {
      searchResults.push({
        type: 'HEADER',
        title: s.subcategoryTitle,
        imageUrl: s.headerImageKey,
        productId: '',
        subProductId: null,
        headerNotes: s.headerNotes,
      });

      Array.prototype.push.apply(searchResults, s.books);
    });

    return searchResults;
  };

  const updateSearchResult = ({ filtered, filterType, filterValue }: ISearchResult) => {
    const searchResults: any[] = mapRawSearchToSearchResults(filtered);
    return setSearchResult({ searchResults, filter: { filterType, filterValue } });
  };

  const search = ({ filterType, filterValue }: IFilter) => {
    const bookMatchedQuery = ({
      query,
      title,
      metadata,
    }: any) => title?.includes(query) || metadata?.includes(query);

    const filterByText = (
      query: string,
      materials: IMaterial[],
    ) => materials?.filter((i) => bookMatchedQuery({
      query,
      title: i.bookTitle,
      metadata: i.metadata,
    }));

    if (filterValue?.length === 0) {
      dispatch(clearSearchResult());
    } else {
      dispatch(updateSearchResult({
        filtered: filterByText(filterValue, packages.materials),
        filterType,
        filterValue,
      }));
    }
  };

  const filter = ({ filterType, filterValue }: IFilter) => {
    const getCurrentFilter = (type: any) => <IFilter>books.filters.find((i: IFilter) => i.filterType === type);

    const filterBySubcategory = (
      title: string,
      materials: IMaterial[],
    ) => materials?.filter((i) => i.subcategoryTitle === title);

    const currentFilter = getCurrentFilter(FILTER_BY_SUBCATEGORY);

    if (currentFilter?.filterValue === filterValue) {
      dispatch(clearSearchResult());
    } else {
      dispatch(updateSearchResult({
        filtered: filterBySubcategory(filterValue, packages.materials),
        filterType,
        filterValue,
      }));
    }
  };

  return { books, search, filter };
};

export default useSearch;
