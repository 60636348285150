import http from '../../utils/httpClient';
import { LOGIN_API, AUTH_API } from './constants';

const authenticate = (email: string, password: string) => http({
  url: LOGIN_API,
  method: 'POST',
  body: { email, password },
});

const verify = (accessToken: string) => http({
  url: AUTH_API,
  method: 'POST',
  accessToken,
  body: {},
});

export default { authenticate, verify };
