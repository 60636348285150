import { useState, useEffect } from 'react';
import { Box, Grid, InputBase, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useAuth from '../../../../../store/auth/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    background: '#004092',
  },
  inputPaddingOverride: {
    background: '#fff',
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

interface IProps {
  'onSearch': Function
  'pageName': string
}

export default ({ onSearch, pageName }: IProps) => {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const { auth: { roles } } = useAuth();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    onSearch({
      filterType: 'search',
      filterValue: event.target.value,
      roles,
      pageName,
    });
  };

  const handleClick = () => onSearch({
    filterType: 'search',
    filterValue: query,
    roles,
    pageName,
  });

  useEffect(() => { setQuery(''); }, [pageName]);

  return (
    <Box mt={2} className={classes.paper}>
      <Grid container>
        <Grid item sm={9} xs={9}>
          <InputBase
            classes={{ input: classes.inputPaddingOverride }}
            fullWidth
            value={query}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={3} xs={3}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleClick}
          >
            GO
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
