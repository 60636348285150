// MAIN (header) images
import logo from './img/logo.jpeg';
import kenshu from './img/kenshu_title_pc.jpeg';
import bunkyo from './img/gakko_title_pc.jpeg';

// CATEGORIES button images
import indexToeic from './img/cat-index-toeic.gif';
import indexToefl from './img/cat-index-toefl.gif';
import indexEiken from './img/cat-index-eiken.gif';
import indexListening from './img/cat-index-listening.gif';
import indexEnglishConversation from './img/cat-index-englishConversation.gif';
import indexReading from './img/cat-index-reading.gif';
import indexWordsAndGrammar from './img/cat-index-wordsAndGrammar.gif';
import indexLighting from './img/cat-index-lighting.gif';
import indexBusiness from './img/cat-index-business.gif';
import indexKidsEnglish from './img/cat-index-kidsEnglish.gif';
import indexJapanese from './img/cat-index-japanese.gif';
import indexLanguage from './img/cat-index-foreignLanguage.gif';
import indexEjArchives from './img/cat-index-ejArchives.gif';
import indexEnglishJournal from './img/cat-index-englishJournal.gif';
import indexEnglishJournalSpecial from './img/cat-index-englishJournalSpecial.gif';
import indexKenshu from './img/cat-index-kenshu.gif';
import indexEnglishForChildren from './img/cat-index-englishForChildren.gif';
import kenshuToeic from './img/cat-kenshu-toeic.gif';
import kenshuEnglishBasics from './img/cat-kenshu-englishBasics.gif';
import kenshuBusinessCommunication from './img/cat-kenshu-businessCommunication.gif';
import kenshuBusinessSkills from './img/cat-kenshu-businessSkills.gif';
import kenshuCustomerService from './img/cat-kenshu-customerService.gif';
import kenshuOther from './img/cat-kenshu-other.gif';
import bunkyoYumeris from './img/cat-bunkyo-yumeris.gif';
import bunkyoListeningPro from './img/cat-bunkyo-listeningPro.gif';
import bunkyoComprehensiveEnglishOne from './img/cat-bunkyo-comprehensiveEnglishOne.gif';
import bunkyoEnglishGrammarComprehensiveWorkbookES from './img/cat-bunkyo-englishGrammarComprehensiveWorkbookES.gif';
import bunkyoEnglishGrammarReactionTraining from './img/cat-bunkyo-englishGrammarReactionTraining.gif';
import bunkyoImprovisationSpeaking from './img/cat-bunkyo-improvisationSpeaking.gif';
import bunkyoSoundTrainingDrill from './img/cat-bunkyo-soundTrainingDrill.gif';
import bunkyoActiveReading from './img/cat-bunkyo-activeReading.gif';
import bunkyo3SetsWorkout from './img/cat-bunkyo-3SetsWorkout.gif';
import bunkyoParachartTraining from './img/cat-bunkyo-parachartTraining.gif';
import bunkyoYumetanWriting from './img/cat-bunkyo-yumetanWriting.gif';
import bunkyoJuniorHighSchoolEnglish from './img/cat-bunkyo-juniorHighSchoolEnglish.gif';
import bunkyoLetsGetStarted from './img/cat-bunkyo-letsGetStarted.gif';
import bunkyoAcademicPack from './img/cat-bunkyo-academicPack.gif';

// CATEGORIES section images
import headerIndexToeic from './img/header-index-toeic.gif';
import headerIndexToefl from './img/header-index-toefl.gif';
import headerIndexEiken from './img/header-index-eiken.gif';
import headerIndexListening from './img/header-index-listening.gif';
import headerIndexEnglishConversation from './img/header-index-englishConversation.gif';
import headerIndexReading from './img/header-index-reading.gif';
import headerIndexWordsAndGrammar from './img/header-index-wordsAndGrammar.gif';
import headerIndexLighting from './img/header-index-lighting.gif';
import headerIndexBusiness from './img/header-index-business.gif';
import headerIndexKidsEnglish from './img/header-index-kidsEnglish.gif';
import headerIndexJapanese from './img/header-index-japanese.gif';
import headerIndexLanguage from './img/header-index-language.gif';
import headerIndexEjArchives from './img/header-index-ejArchives.gif';
import headerIndexEnglishJournal from './img/header-index-englishJournal.gif';
import headerIndexEnglishJournalSpecial from './img/header-index-englishJournalSpecial.gif';
import headerIndexEnglishForChildren from './img/header-index-englishForChildren.gif';
import headerKenshuToeic from './img/header-kenshu-toeic.gif';
import headerKenshuEnglishBasics from './img/header-kenshu-englishBasics.gif';
import headerKenshuBusinessCommunication from './img/header-kenshu-businessCommunication.gif';
import headerKenshuBusinessSkills from './img/header-kenshu-businessSkills.gif';
import headerKenshuCustomerService from './img/header-kenshu-customerService.gif';
import headerKenshuOther from './img/header-kenshu-other.gif';
import headerBunkyoYumeris from './img/header-bunkyo-yumeris.gif';
import headerBunkyoListeningPro from './img/header-bunkyo-listeningPro.gif';
import headerBunkyoComprehensiveEnglishOne from './img/header-bunkyo-comprehensiveEnglishOne.gif';
import headerBunkyoEnglishGrammarComprehensiveWorkbookES from './img/header-bunkyo-englishGrammarComprehensiveWorkbookES.gif';
import headerBunkyoEnglishGrammarReactionTraining from './img/header-bunkyo-englishGrammarReactionTraining.gif';
import headerBunkyoImprovisationSpeaking from './img/header-bunkyo-improvisationSpeaking.gif';
import headerBunkyoSoundTrainingDrill from './img/header-bunkyo-soundTrainingDrill.gif';
import headerBunkyoActiveReading from './img/header-bunkyo-activeReading.gif';
import headerBunkyo3SetsWorkout from './img/header-bunkyo-3SetsWorkout.gif';
import headerBunkyoParachartTraining from './img/header-bunkyo-parachartTraining.gif';
import headerBunkyoYumetanWriting from './img/header-bunkyo-yumetanWriting.gif';
import headerBunkyoJuniorHighSchoolEnglish from './img/header-bunkyo-juniorHighSchoolEnglish.gif';
import headerBunkyoLetsGetStarted from './img/header-bunkyo-letsGetStarted.gif';
import headerBunkyoAcademicPack from './img/header-bunkyo-academicPack.gif';

// BANNER images
import boocoDesktop from './img/booco-750.jpeg';
import boocoMobile from './img/booco-460.jpeg';

// PAGE JP Online
import pageJpOnlineBtnJap from './img/page_jponline_btn_enter_jp.png';
import pageJpOnlineBtnEng from './img/page_jponline_btn_enter_en.png';

const IMAGES: any = {
  logo,
  boocoDesktop,
  boocoMobile,
  kenshu,
  bunkyo,
  indexToeic,
  indexToefl,
  indexEiken,
  indexListening,
  indexEnglishConversation,
  indexReading,
  indexWordsAndGrammar,
  indexLighting,
  indexBusiness,
  indexKidsEnglish,
  indexJapanese,
  indexLanguage,
  indexEjArchives,
  indexEnglishJournal,
  indexEnglishJournalSpecial,
  indexKenshu,
  indexEnglishForChildren,
  kenshuToeic,
  kenshuEnglishBasics,
  kenshuBusinessCommunication,
  kenshuBusinessSkills,
  kenshuCustomerService,
  kenshuOther,
  bunkyoYumeris,
  bunkyoListeningPro,
  bunkyoComprehensiveEnglishOne,
  bunkyoEnglishGrammarComprehensiveWorkbookES,
  bunkyoEnglishGrammarReactionTraining,
  bunkyoImprovisationSpeaking,
  bunkyoSoundTrainingDrill,
  bunkyoActiveReading,
  bunkyo3SetsWorkout,
  bunkyoParachartTraining,
  bunkyoYumetanWriting,
  bunkyoJuniorHighSchoolEnglish,
  bunkyoLetsGetStarted,
  bunkyoAcademicPack,
  headerIndexToeic,
  headerIndexToefl,
  headerIndexEiken,
  headerIndexListening,
  headerIndexEnglishConversation,
  headerIndexReading,
  headerIndexWordsAndGrammar,
  headerIndexLighting,
  headerIndexBusiness,
  headerIndexKidsEnglish,
  headerIndexJapanese,
  headerIndexLanguage,
  headerIndexEjArchives,
  headerIndexEnglishJournal,
  headerIndexEnglishJournalSpecial,
  headerIndexEnglishForChildren,
  headerKenshuToeic,
  headerKenshuEnglishBasics,
  headerKenshuBusinessCommunication,
  headerKenshuBusinessSkills,
  headerKenshuCustomerService,
  headerKenshuOther,
  headerBunkyoYumeris,
  headerBunkyoListeningPro,
  headerBunkyoComprehensiveEnglishOne,
  headerBunkyoEnglishGrammarComprehensiveWorkbookES,
  headerBunkyoEnglishGrammarReactionTraining,
  headerBunkyoImprovisationSpeaking,
  headerBunkyoSoundTrainingDrill,
  headerBunkyoActiveReading,
  headerBunkyo3SetsWorkout,
  headerBunkyoParachartTraining,
  headerBunkyoYumetanWriting,
  headerBunkyoJuniorHighSchoolEnglish,
  headerBunkyoLetsGetStarted,
  headerBunkyoAcademicPack,
  pageJpOnlineBtnJap,
  pageJpOnlineBtnEng,
};

export default IMAGES;
