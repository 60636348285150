import authReducer from './auth/reducers';
import bookReducer from './book/reducers';
import localReducer from './local/reducers';
import materialReducer from './material/reducers';
import versionReducer from './version/reducers';

export default ({ auth, books, material, local, version }: any, action: any) => ({
  auth: authReducer(auth, action),
  books: bookReducer(books, action),
  local: localReducer(local, action),
  material: materialReducer(material, action),
  version: versionReducer(version, action),
});
