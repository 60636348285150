/* eslint no-nested-ternary: */
import { useState } from 'react';
import { Typography, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import VisibilityOn from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Title = styled(Typography)(({ theme }) => ({ color: theme.palette.text.primary }));

const TextInput = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  background: '#fff',
}));

interface IProp {
  onChange: Function,
  label: string,
  type: string,
  autoFocus?: boolean
  placeholder?: string,
  error?: boolean
  helperText?: string
  password?: boolean,
}

export default ({ onChange, label, type, autoFocus = false, placeholder = '', error = false, helperText = '', password = false }: IProp) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <Title variant="caption">{label}</Title>
      <TextInput
        InputProps={password ? {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOn /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        } : {}}
        autoFocus={autoFocus}
        size="small"
        fullWidth
        variant="outlined"
        placeholder={placeholder}
        type={password ? showPassword ? 'text' : 'password' : type}
        onChange={(e) => onChange(e.target.value)}
        error={error}
        helperText={error ? helperText : ''}
      />
    </>
  );
};
