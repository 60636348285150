import { useEffect } from 'react';
import { useStateValue } from '../../index';
import { PageLoadingStatus } from '../constants';
import {
  bookLoadingStarted,
  bookLoadedWithSuccess,
  bookLoadedWithError,
} from '../actions';
import useQuery from '../queries';

interface IValidateProp {
  roles: string[]
  verifyUser: Function
  pageRole: string
  needAuth: boolean
}

interface IUseFetchProp {
  roles: string[]
  pageName: string
  verifyUser: Function
}

export const validate = async ({ roles, verifyUser, pageRole, needAuth }: IValidateProp) => {
  const userNotAllowed = roles.find((role: string) => role === pageRole) === undefined;
  if (userNotAllowed) throw Error('forbidden');
  if (needAuth) await verifyUser();
};

const useFetch = ({ roles, pageName, verifyUser }: IUseFetchProp) => {
  const [{ books, version }, dispatch] = useStateValue();
  const { fetchBooks } = useQuery(version.id, pageName);

  const loadBooks = async () => {
    dispatch(bookLoadingStarted());
    try {
      const [structure, packages] = await fetchBooks();
      await validate({
        roles,
        pageRole: structure.role,
        needAuth: structure.metadata.needAuth,
        verifyUser,
      });
      dispatch(bookLoadedWithSuccess({ structure, packages }));
    } catch (_) {
      dispatch(bookLoadedWithError());
    }
  };

  useEffect(() => { loadBooks(); }, [pageName]);

  return {
    books,
    loading: books?.bookLoadingState === PageLoadingStatus.LOADING,
    error: books?.bookLoadingState === PageLoadingStatus.LOADED_ERROR,
  };
};

export default useFetch;
