/* eslint-disable no-restricted-globals */
import { useLayoutEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Link } from '@material-ui/core';

export default () => {
  const { pageName } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const SP_SCHEME = `alcoapp://webview?URL=${process.env.REACT_APP_PORTAL_URL}/${pageName}`;
    const userAgent = navigator.userAgent.toLowerCase();
    const isDeepLink = pathname.substring(0, 6) === '/alco/' || pathname.substring(0, 5) === '/alco';
    const isMobileUserAgent = userAgent.search(/iphone|ipad|ipod|android|opera.*mini|windows.*phone/) > -1 || userAgent.indexOf('macintosh') > -1;
    const hasTouchEndHandler = 'ontouchend' in document;
    const shouldOpenApp = isMobileUserAgent && hasTouchEndHandler && isDeepLink;

    if (shouldOpenApp) {
      setTimeout(() => { location.href = SP_SCHEME; }, 500);
    } else if (isDeepLink) {
      navigate(`/${pageName}`);
    }
  });

  return <Link href={`${process.env.REACT_APP_PORTAL_URL}/${pageName}`}>自動遷移しない場合コチラをクリック</Link>;
};
