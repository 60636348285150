// LAYOUT
import PublicLayout from './layouts/Public';
import AuthLayout from './layouts/Auth';
import IFrameLayout from './layouts/Public/iframe';

// PAGES
import LoginPage from './pages/login';
import LoginSuccessPage from './pages/login/success';
import HomePage from './pages/packages';
import AlcoHomePage from './pages/packages/alco';
import AlcosHomePage from './pages/packages/alcos';
import BookDetailsPage from './pages/material_details';
import AlcoBookDetailsPage from './pages/material_details/alco';
import StaticMaterialsPage from './pages/static_materials';
import CustomJpnOnlinePage from './pages/custom_jpnonline';
import RegistrationPage from './pages/register';
import SuccessPage from './pages/success';
import ResetPasswordPage from './pages/reset_password';
import RequestResetPage from './pages/request_password_reset';
import EmailConfirmedPage from './pages/email_confirmed';
import StudentLayout from './layouts/Student';

import PageNotFound from './pages/@common/components/PageNotFound';

const routes = [
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/alco', element: <AlcoHomePage /> },
    ],
  },
  {
    path: '/login',
    element: <AuthLayout />,
    children: [
      { path: '/', element: <LoginPage /> },
    ],
  },
  {
    path: '/',
    element: <IFrameLayout />,
    children: [
      { path: '/alc_selfdevelopment', element: <StaticMaterialsPage /> },
    ],
  },
  {
    path: '/',
    element: <StudentLayout />,
    children: [
      { path: '/register', element: <RegistrationPage /> },
      { path: '/success', element: <SuccessPage /> },
      { path: '/reset_password/:token', element: <ResetPasswordPage /> },
      { path: '/request_reset_password', element: <RequestResetPage /> },
      { path: '/user_confirmation', element: <EmailConfirmedPage /> },
      { path: '/login/success', element: <LoginSuccessPage /> },
    ],
  },
  {
    path: '/',
    element: <IFrameLayout />,
    children: [
      { path: '/dl/2020naitei', element: <StaticMaterialsPage /> },
      { path: '/dl/naitei', element: <StaticMaterialsPage /> },
      { path: '/dl/sample01', element: <StaticMaterialsPage /> },
      { path: '/dl/kaigo-nihongo', element: <StaticMaterialsPage /> },
      { path: '/dl/gotcha001', element: <StaticMaterialsPage /> },
      { path: '/dl/dl-test/sample', element: <StaticMaterialsPage /> },
      { path: '/dl/7014033_01', element: <StaticMaterialsPage /> },
      { path: '/dl/7014033_01/speaking', element: <StaticMaterialsPage /> },
      { path: '/dl/7014033_01/writing', element: <StaticMaterialsPage /> },
      { path: '/dl/7014033_02', element: <StaticMaterialsPage /> },
      { path: '/dl/7014033_02/speaking', element: <StaticMaterialsPage /> },
      { path: '/dl/7014033_02/writing', element: <StaticMaterialsPage /> },
    ],
  },
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      { path: '/jpnonline', element: <PageNotFound /> },
      { path: '/dl/jpnonline', element: <CustomJpnOnlinePage /> },
      { path: '/:pageName', element: <HomePage /> },
      { path: '/alco/:pageName', element: <AlcosHomePage /> },
      { path: '/dl/:productId/:subProductId', element: <BookDetailsPage /> },
      { path: '/dl/:productId', element: <BookDetailsPage /> },
      { path: '/dl/alco/:productId', element: <AlcoBookDetailsPage /> },
      { path: '/dl/alco/:productId/:subProductId', element: <AlcoBookDetailsPage /> },
    ],
  },
  { path: '*', element: <>お探しのページは見つかりません。</> },
];

export default routes;
