import { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  'url': string
  'title': string
  'width': number
  'height': number
}

export default ({ url, title, width, height }: IProps) => {
  const useStyles = makeStyles({
    img: {
      width: `${width}px`,
      height: `${height}px`,
    },
    hidden: { display: 'none' },
  });

  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);

  const imageLoaded = () => setLoaded(true);

  return (
    <>
      {!loaded && (
        <>
          <Skeleton variant="rect" width={width} height={height} />
          <img
            className={classes.hidden}
            src={url}
            alt={title}
            aria-label={title}
            onLoad={imageLoaded}
          />
        </>
      )}
      {loaded && <img className={classes.img} src={url} alt={title} aria-label={title} />}
    </>
  );
};
