import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import { INITIAL_STATE as AUTH_INITIAL_STATE } from './store/auth/reducers';
import { INITIAL_STATE as BOOK_INITIAL_STATE } from './store/book/reducers';
import { INITIAL_STATE as MATERIAL_INITIAL_STATE } from './store/material/reducers';
import { INITIAL_STATE as LOCAL_INITIAL_STATE } from './store/local/reducers';
import { INITIAL_STATE as VERSION_INITIAL_STATE } from './store/version/reducers';

import reducers from './store/reducers';
import theme from './pages/@common/styles';
import routes from './routes';
import { StateProvider } from './store';

const Routes = () => useRoutes(routes);

const initialState = {
  auth: AUTH_INITIAL_STATE,
  books: BOOK_INITIAL_STATE,
  material: MATERIAL_INITIAL_STATE,
  local: LOCAL_INITIAL_STATE,
  version: VERSION_INITIAL_STATE,
};

export default () => (
  <ThemeProvider theme={theme}>
    <StateProvider initialState={initialState} reducer={reducers}>
      <Router>
        <Routes />
      </Router>
    </StateProvider>
  </ThemeProvider>
);
