/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-mixed-operators */
import { useEffect, useState, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box } from '@material-ui/core';
import BackToHomeButton from './components/BackToHomeButton';

import './style.css';

const useStyles = makeStyles((theme) => ({
  base: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 0,
      margin: 0,
    },
    [theme.breakpoints.up('sm')]: { width: '750px !important', padding: '16px 0' },
  },
  wrap: { overflow: 'hidden' },
}));

export default () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [productId, setProductId] = useState<any>(null);
  const [subProductId, setSubProductId] = useState<any>(null);
  const [s3Url, setS3Url] = useState<any>(null);
  const [pageTitle, setPageTitle] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/dl/2020naitei' || pathname === '/dl/2020naitei/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/2020naitei/index.html`);
      setPageTitle('2020年度版　内定者様向け　アルク英語自己学習プログラムガイド　ダウンロードサイト｜ダウンロードセンター｜アルク');
      setProductId('2020naitei');
      setSubProductId(null);
    } else if (pathname === '/dl/sample01' || pathname === '/dl/sample01/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/sample01/index.html`);
      setPageTitle('アルク ALCO対応 コンテンツダウンロードページ（サンプル）｜ダウンロードセンター｜アルク');
      setProductId('sample01');
      setSubProductId(null);
    } else if (pathname === '/dl/dl-test/sample' || pathname === '/dl/dl-test/sample/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/dl-test/sample.html`);
      setPageTitle('企業人事担当者様向け通信講座サンプルダウンロード｜ダウンロードセンター｜アルク');
      setProductId('dl-test');
      setSubProductId('sample');
    } else if (pathname === '/dl/naitei' || pathname === '/dl/naitei/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/naitei/index.html`);
      setPageTitle('2020年度版　内定者様向け　アルク英語自己学習プログラムガイド　ダウンロードサイト｜ダウンロードセンター｜アルク');
      setProductId('naitei');
      setSubProductId(null);
    } else if (pathname === '/dl/kaigo-nihongo' || pathname === '/dl/kaigo-nihongo/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/kaigo-nihongo/index.html`);
      setPageTitle('「介護の日本語（かいごのにほんご）」ダウンロードサイト｜ダウンロードセンター｜アルク');
      setProductId('kaigo-nihongo');
      setSubProductId(null);
    } else if (pathname === '/dl/gotcha001' || pathname === '/dl/gotcha001/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/gotcha001/index.html`);
      setPageTitle('サンプル音声｜ダウンロードセンター｜アルク');
      setProductId('gotcha001');
      setSubProductId(null);
    } else if (pathname === '/dl/7014033_01' || pathname === '/dl/7014033_01/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/7014033_01/index.html`);
      setPageTitle('Speaking Test');
      setProductId('7014033_01');
      setSubProductId(null);
    } else if (pathname === '/dl/7014033_01/speaking' || pathname === '/dl/7014033_01/speaking/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/7014033_01/speaking.html`);
      setPageTitle('Speaking Test');
      setProductId('7014033_01');
      setSubProductId('speaking');
    } else if (pathname === '/dl/7014033_01/writing' || pathname === '/dl/7014033_01/writing/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/7014033_01/writing.html`);
      setPageTitle('Speaking Test');
      setProductId('7014033_01');
      setSubProductId('writing');
    } else if (pathname === '/dl/7014033_02' || pathname === '/dl/7014033_02/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/7014033_02/index.html`);
      setPageTitle('Speaking Test');
      setProductId('7014033_02');
      setSubProductId(null);
    } else if (pathname === '/dl/7014033_02/speaking' || pathname === '/dl/7014033_02/speaking/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/7014033_02/speaking.html`);
      setPageTitle('Speaking Test');
      setProductId('7014033_02');
      setSubProductId('speaking');
    } else if (pathname === '/dl/7014033_02/writing' || pathname === '/dl/7014033_02/writing/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/7014033_02/writing.html`);
      setPageTitle('Speaking Test');
      setProductId('7014033_02');
      setSubProductId('writing');
    } else if (pathname === '/alc_selfdevelopment' || pathname === '/alc_selfdevelopment/') {
      setS3Url(`${process.env.REACT_APP_STATIC_HOSTING_URL}/list/alc_selfdevelopment.html`);
      setPageTitle('英語自己学習プログラム ダウンロードサイト｜アルク');
      setProductId('list');
      setSubProductId('alc_selfdevelopment');
    }
  }, []);

  useLayoutEffect(() => {
    const subId = subProductId || '';
    const SP_SCHEME = `alcoapp://webview?URL=${process.env.REACT_APP_PORTAL_URL}/dl/${productId}/${subId}`;
    const userAgent = navigator.userAgent.toLowerCase();
    const isDeepLink = pathname.substring(0, 9) === '/dl/alco/';
    const isMobileUserAgent = userAgent.search(/iphone|ipad|ipod|android|opera.*mini|windows.*phone/) > -1 || userAgent.indexOf('macintosh') > -1;
    const hasTouchEndHandler = 'ontouchend' in document;
    const shouldOpenApp = isMobileUserAgent && hasTouchEndHandler && isDeepLink;

    if (shouldOpenApp) {
      setTimeout(() => { location.href = SP_SCHEME; }, 500);
    } else if (isDeepLink) {
      navigate(`/dl/${productId}/${subId}`);
    }

    document.title = pageTitle ?? 'お探しのページは見つかりません';
  });

  return (
    <Box style={{ width: '100vw', height: '100vh' }}>
      {s3Url && productId === 'list' && subProductId === 'alc_selfdevelopment'
        ? (
          <Box style={{ width: '100vw', height: '100vh', overflowY: 'hidden' }}>
            <Container className={classes.base}>
              <Box className={classes.wrap} display={{ xs: 'none', sm: 'block' }}>
                <BackToHomeButton />
              </Box>
            </Container>
            <iframe id="static-bar" src={s3Url} />
          </Box>
        )
        : (
          <Box style={{ width: '100vw', height: '100vh' }}>
            <iframe id="static" src={s3Url} />
          </Box>
        )}
    </Box>
  );
};
