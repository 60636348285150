import {
  FORM_INACTIVE,
  FORM_SUBMITTING,
  FORM_SUBMITTED_SUCCESS,
  FORM_SUBMITTED_ERROR,
} from './actions';
import { FormStatus } from './constants';

export const INITIAL_STATE = { formState: FormStatus.INACTIVE };

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FORM_INACTIVE:
      return {
        ...state,
        formState: FormStatus.INACTIVE,
      };

    case FORM_SUBMITTING:
      return {
        ...state,
        formState: FormStatus.SUBMITTING,
      };
    case FORM_SUBMITTED_SUCCESS:
      return {
        ...state,
        formState: FormStatus.SUBMITTED_SUCCESS,
      };
    case FORM_SUBMITTED_ERROR:
      return {
        ...state,
        formState: FormStatus.SUBMITTED_ERROR,
      };
    default:
      return state;
  }
};
