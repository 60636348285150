/* eslint-disable no-unused-vars */
import { PageLoadingStatus } from './constants';

import {
  DATA_LOADING,
  DATA_LOADED_SUCCESS,
  DATA_LOADED_ERROR,
} from './actions';

export const INITIAL_STATE = {
  id: null,
  pageLoadingState: PageLoadingStatus.LOADING,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        id: null,
        pageLoadingState: PageLoadingStatus.LOADING,
      };
    case DATA_LOADED_SUCCESS:
      return {
        ...state,
        id: action.payload,
        pageLoadingState: PageLoadingStatus.LOADED_SUCCESS,
      };
    case DATA_LOADED_ERROR:
      return {
        ...state,
        id: null,
        pageLoadingState: PageLoadingStatus.LOADED_ERROR,
      };
    default:
      return state;
  }
};
