import { useStateValue } from '../index';
import { PageLoadingStatus } from '../constants';
import { pageLoadingStarted, pageLoadedWithSuccess } from '../actions';
import useQuery from '../queries';

const useFetch = () => {
  const { state, dispatch } = useStateValue();
  const fetchRoles = useQuery();

  const fetch = async () => {
    dispatch(pageLoadingStarted());
    const roles = await fetchRoles();
    dispatch(pageLoadedWithSuccess(roles));
  };

  return {
    roles: state?.roles,
    loading: state?.pageLoadingState === PageLoadingStatus.LOADING || state?.pageLoadingState === PageLoadingStatus.INITIAL,
    fetch,
  };
};

export default useFetch;
