/* eslint-disable no-unused-vars */
import { Box, Button, Snackbar, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import Alert from '@material-ui/lab/Alert';
import { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import FullpageLoader from '../../@common/components/forms/FullpageLoader';
import FullWidthTextField from '../../@common/components/forms/FullWidthTextField';
import useForm from '../store/hooks/useForm';
import { PageType } from '../../success/constants';
import useLocal from '../../../store/local/hooks/useLocal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  default: {
    textTransform: 'none',
    color: `${theme.palette.text.primary} !important`,
    background: theme.palette.background.default,
    outline: 'none',
    '&:hover': { background: theme.palette.background.paper },
    margin: '0px 13px 0px 0px',
  },
  primary: {
    textTransform: 'none',
    color: 'white',
    background: theme.palette.primary.main,
    outline: 'none',
    '&:hover': { background: '#205f82' },
    borderColor: blue[500],
  },
}));
export default () => {
  const { text } = useLocal();
  const navigate = useNavigate();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const { submitting, submittedWithSuccess, submittedWithError, resetFormState, submitForm } = useForm();
  const validateEmail = (val:string) => new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i).test(val);

  const handleSubmit = (e:any) => {
    e.preventDefault();
    setEmailError(false);
    let errors = 0;
    if (!validateEmail(email)) {
      setEmailError(true);
      errors += 1;
    }
    if (errors === 0) {
      submitForm(email);
    }
  };

  useEffect(() => {
    if (submittedWithSuccess) {
      resetFormState();
      navigate('/success', { replace: true, state: { type: PageType.REQUEST_PASSWORD_RESET } });
    }
  }, [submittedWithSuccess]);

  return (
    <>
      <Container maxWidth="xs">
        <Box
          boxShadow={3}
          bgcolor="background.paper"
          marginTop={20}
          m={1}
          p={1}
        >

          <form className={classes.root} onSubmit={handleSubmit} noValidate>
            <FullWidthTextField
              onChange={(value:any) => { setEmail(value); }}
              label={text.request_reset.form.emailLabel}
              type="email"
              error={emailError}
              helperText={text.request_reset.form.emailError}
            />
            <Box height={10} />
            <div>
              <Button variant="contained" className={classes.default} component={RouterLink} to="/login">
                {text.buttons.cancel}
              </Button>
              <Button type="submit" variant="contained" className={classes.primary}>
                {text.buttons.reset_password}
              </Button>
            </div>
          </form>
        </Box>
      </Container>
      <FullpageLoader open={submitting} />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={submittedWithError}
        autoHideDuration={3000}
        onClose={resetFormState}
      >
        <Alert onClose={() => resetFormState} severity="error">
          Something went wrong, please try again later.
        </Alert>
      </Snackbar>

    </>
  );
};
