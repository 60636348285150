import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({ img: { width: '100%' } });

interface IProp {
  'alt': string
  'src': string
}

export default ({ alt, src }: IProp) => {
  const classes = useStyles();

  return (
    <img
      className={classes.img}
      alt={alt}
      src={src}
    />
  );
};
