import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BookTitle from './components/BookTitle';
import BookDescription from './components/BookDescription';

const useStyles = makeStyles((theme) => ({ root: { [theme.breakpoints.up('sm')]: { marginTop: theme.spacing(4) } } }));

interface IProps {
  'title': string
  'description': string
}

export default ({ title, description }: IProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <BookTitle text={title} />
      <BookDescription text={description} />
    </Box>
  );
};
