/* eslint-disable object-curly-newline */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useStateValue } from '../index';
import {
  formSubmitted,
  formSubmittedWithSuccess,
  formSubmittedWithError,
  formStateReset,
} from '../actions';
import { FormStatus, RESET_PASSWORD_API } from '../constants';

import httpClient from '../../../../utils/httpClient';

const useForm = () => {
  const { state, dispatch } = useStateValue();

  const handle500 = ({ _ }: any) => dispatch(formSubmittedWithError());

  const submitForm = (password:string, token:string) => {
    const url = `${RESET_PASSWORD_API}`;

    const handle200 = (response: any) => {
      if (response.data) {
        dispatch(formSubmittedWithSuccess());
      } else if (response.message) {
        dispatch(formSubmittedWithError());
      }
    };

    dispatch(formSubmitted());

    const body: any = {};
    body.password = password;
    body.onetime_token = token;

    httpClient({ url, method: 'POST', body })
      .then((res: any) => handle200(res))
      .catch((error: any) => handle500(error));
  };

  const resetFormState = () => dispatch(formStateReset());

  return {
    submitting: state.formState === FormStatus.SUBMITTING,
    submittedWithSuccess: state.formState === FormStatus.SUBMITTED_SUCCESS,
    submittedWithError: state.formState === FormStatus.SUBMITTED_ERROR,
    submitForm,
    resetFormState,
  };
};

export default useForm;
