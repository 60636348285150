import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import useLocal from '../../../../../store/local/hooks/useLocal';

interface IProp {
  open: boolean,
  bookPassword: string | null,
  handleClose: Function,
  openProductDetailsPage: Function,
}

export default ({ open, bookPassword, handleClose, openProductDetailsPage }: IProp) => {
  const { text } = useLocal();
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const resetForm = () => {
    setError(false);
    setPassword('');
    handleClose();
  };

  const validate = () => {
    const isPasswordValid = password === bookPassword;

    if (isPasswordValid) openProductDetailsPage();

    setError(true);
  };

  return (
    <Dialog open={open} onClose={resetForm} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
      <DialogTitle id="form-dialog-title">{text.packages.search.passwordModal.dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text.packages.search.passwordModal.content}
        </DialogContentText>
        <TextField
          error={error}
          autoFocus
          margin="dense"
          id="password-field"
          label={text.packages.search.passwordModal.label}
          type="password"
          fullWidth
          autoComplete="book-password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
          helperText={error ? text.packages.search.passwordModal.incorrect : ''}
        />
      </DialogContent>
      <DialogActions style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}>
        <Button onClick={resetForm}>
          {text.packages.search.passwordModal.close}
        </Button>
        <Button disabled={password.length === 0} onClick={validate} variant="contained" color="primary">
          {text.packages.search.passwordModal.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
