import { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  CardHeader,
  Link,
  Button,
  Divider,
  Avatar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';
import BookImage from '../../../../@common/components/BookImage';
import PasswordModal from './PasswordModal';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '80px',
    height: '111px',
  },
  cardContent: {
    display: 'flex',
    padding: '16px',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: { marginBottom: 0 },
    [theme.breakpoints.up('sm')]: { marginBottom: '20px' },
  },
  buttonLink: {
    padding: 0,
    color: '#0041a5',
    fontSize: '0.875rem',
    '&:hover': {
      color: '#79a6ea',
      background: '#fafafa',
      textDecoration: 'none',
    },
  },
  downloadNotes: { marginTop: '5px', fontWeight: 'bold', fontSize: '0.6rem' },
}));

export interface IBook {
  'productId': string,
  'subProductId': string | null
  'title': string,
  'imageUrl': string
  'needAuth': boolean
  'password': string | null
  'passwordId': string | null
  'downloadNotes': string | null
}

interface IProps {
  'item': IBook
}

export default ({ item }: IProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const passwordId = item.productId === '9200508' ? '01888' : item.passwordId;
  const onValidPassword = () => navigate(`/dl/${item.productId}/${passwordId}`);

  const link = item.subProductId
    ? `/dl/${item.productId}/${item.subProductId}`
    : `/dl/${item.productId}`;

  const downloadText = '[ダウンロード]';
  const LinkView = (
    <>
      <Link className={classes.buttonLink} component={RouterLink} to={link}>{downloadText}</Link>
      {item.downloadNotes && <Typography component="p" className={classes.downloadNotes}>{item.downloadNotes}</Typography>}
    </>
  );
  const PopupView = (
    <>
      <Button className={classes.buttonLink} onClick={handleClickOpen} disableRipple color="primary">{downloadText}</Button>
      {item.downloadNotes && <Typography component="p" className={classes.downloadNotes}>{item.downloadNotes}</Typography>}
    </>
  );
  const BookImageView = <BookImage title={item.title} url={item.imageUrl} width={80} height={111} />;
  const ImagePlaceholder = <Avatar style={{ height: '111px', width: '80px' }} variant="square" alt={item.title}><InsertPhotoOutlinedIcon style={{ fontSize: 50 }} /></Avatar>;

  return (
    <>
      <Grid key={item.title} item xs={12} sm={6}>
        <CardHeader
          classes={{ root: classes.cardContent }}
          avatar={item.imageUrl ? BookImageView : ImagePlaceholder}
          title={<b>{item.title}</b>}
          subheader={item.needAuth ? PopupView : LinkView}
        />
        <Divider />
      </Grid>
      <PasswordModal
        open={open}
        bookPassword={item.password}
        openProductDetailsPage={onValidPassword}
        handleClose={handleClose}
        productId={item.productId}
      />
    </>
  );
};
