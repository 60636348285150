/* eslint-disable no-unused-vars */
import { useStateValue } from '../../index';
import { PageLoadingStatus } from '../constants';
import {
  bookLoadingStarted,
  bookLoadedWithSuccess,
  bookLoadedWithError,
} from '../actions';
import useQuery from '../queries';

const useFetch = () => {
  const { fetchDetails, fetchRef } = useQuery();
  const [state, dispatch] = useStateValue();
  const { material, version } = state;

  interface IProp {
    roles: string[]
    productId: string
    subProductId: string | null
  }

  const loadDetails = async ({ roles, productId, subProductId }: IProp) => {
    dispatch(bookLoadingStarted());

    try {
      const filename = roles
        .filter((role) => role !== 'public')
        .sort()
        .concat(['public'])
        .join('-');
      const ref = await fetchRef(version.id, filename);

      if (!ref.data.includes(productId)) {
        dispatch(bookLoadedWithError());
        return;
      }

      const id = subProductId ? `${productId}-${subProductId}` : productId;
      const details = await fetchDetails(version.id, id);

      dispatch(bookLoadedWithSuccess(details));
    } catch (err) {
      dispatch(bookLoadedWithError());
    }
  };

  return {
    material: material.data,
    bookLoading: material?.bookLoadingState === PageLoadingStatus.LOADING,
    bookLoadingError: material?.bookLoadingState === PageLoadingStatus.LOADED_ERROR,
    loadDetails,
  };
};

export default useFetch;
