import http from '../../../utils/httpClient';
import { ROLES_JSON_PATH } from './constants';

const useQuery = () => {
  const fetchRoles = () => http({ url: ROLES_JSON_PATH });

  return fetchRoles;
};

export default useQuery;
