import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({ toListButton: { color: `${theme.palette.text.primary} !important` } }));

export default () => {
  const classes = useStyles();

  return (
    <Button
      className={classes.toListButton}
      component={RouterLink}
      size="small"
      disableElevation
      variant="contained"
      to="/"
      startIcon={<ArrowBackIcon />}
    >
      ダウンロードセンタートップへ
    </Button>
  );
};
