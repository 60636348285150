/* eslint-disable no-unused-vars */
import { Box, Typography, Container, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import EmailIcon from '@material-ui/icons/Email';
import CheckIcon from '@material-ui/icons/CheckCircle';
import PasswordIcon from '@material-ui/icons/VpnKey';
import ResetIcon from '@material-ui/icons/Lock';
import blue from '@material-ui/core/colors/blue';
import useLocal from '../../store/local/hooks/useLocal';

import { PageType } from './constants';

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: 1,
    marginTop: '20px',
    fontSize: '1.5rem',
  },
  subtitle: {
    lineHeight: 1,
    marginTop: '20px',
    marginBottom: '15px',
    fontSize: '1rem',
    color: '#757575',
  },
  note: {
    lineHeight: 1,
    marginTop: '12px',
    marginBottom: '12px',
    fontSize: '0.8rem',
    color: '#989898',
  },
  alignItemsAndJustifyContent: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  largeIcon: {
    fontSize: '600%',
    color: theme.palette.primary.main,
    marginTop: '10px',
  },
  button: {
    textTransform: 'none',
    color: 'white',
    background: theme.palette.primary.main,
    outline: 'none',
    '&:hover': { background: '#205f82' },
    borderColor: blue[500],
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const renderSwitch = (type: string, classes: any, text: any) => {
  switch (type) {
    case PageType.REQUEST_PASSWORD_RESET:
      return {
        title: text.success.request_reset.title,
        subTitle: text.success.request_reset.subTitle,
        icon: <ResetIcon className={classes.largeIcon} />,
        showLoginButton: false,
        showHomeButton: true,
        showNote: true,
      };
    case PageType.PASSWORD_RESET:
      return {
        title: text.success.reset_password.title,
        subTitle: text.success.reset_password.subTitle,
        icon: <PasswordIcon className={classes.largeIcon} />,
        showLoginButton: true,
        showHomeButton: false,
        showNote: false,
      };
    case PageType.EMAIL_CONFIRMED:
      return {
        title: text.success.email_confirmed.title,
        subTitle: text.success.email_confirmed.subTitle,
        icon: <EmailIcon className={classes.largeIcon} />,
        showLoginButton: true,
        showHomeButton: false,
        showNote: false,
      };
    default:
      return {
        title: text.success.registration.title,
        subTitle: text.success.registration.subTitle,
        icon: <CheckIcon className={classes.largeIcon} />,
        showLoginButton: false,
        showHomeButton: true,
        showNote: true,
      };
  }
};

export default () => {
  const { text } = useLocal();
  const { state }: { state: any } = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { title, subTitle, icon, showLoginButton, showHomeButton, showNote } = renderSwitch((state !== null ? state.type : ''), classes, text);
  return (
    <Container maxWidth="xs">
      <Box
        className={classes.alignItemsAndJustifyContent}
        boxShadow={3}
        bgcolor="background.paper"
        marginTop={20}
        m={1}
        p={1}
      >
        {icon}
        <Typography className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.subtitle}>
          {subTitle}
        </Typography>

        {showNote && (
          <Typography className={classes.note}>
            {text.success.email_note}
          </Typography>
        )}

        {showLoginButton && (
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => navigate('/login', { replace: true })}
          >
            {text.buttons.login}
          </Button>
        )}
        {showHomeButton && (
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => navigate('/', { replace: true })}
          >
            {text.buttons.home}
          </Button>
        )}
      </Box>
    </Container>
  );
};
