import { styled } from '@material-ui/core/styles';

const H3 = styled('h3')(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'row',
  fontWeight: 'bold',
  fontSize: '1rem',
  letterSpacing: '0.00938em',
  '&::after': {
    content: '""',
    flex: '1 1',
    borderBottom: '1px solid',
    margin: 'auto',
    marginLeft: '10px',
  },
}));

interface IProps {
  'text': string
}

export default ({ text }: IProps) => <H3>{text}</H3>;
