export const BOOK_LOADING = 'books/BOOK_LOADING';
export const BOOK_LOADED_SUCCESS = 'books/BOOK_LOADED_SUCCESS';
export const BOOK_LOADED_ERROR = 'books/BOOK_LOADED_ERROR';
export const bookLoadingStarted = () => ({ type: BOOK_LOADING });
export const bookLoadedWithSuccess = (payload: any) => ({ payload, type: BOOK_LOADED_SUCCESS });
export const bookLoadedWithError = () => ({ type: BOOK_LOADED_ERROR });

export const FILTER_BOOKS = 'books/FILTER';
export const CLEAR_FILTER = 'books/CLEAR_FILTER';
export const setSearchResult = (payload: any) => ({ payload, type: FILTER_BOOKS });
export const clearSearchResult = () => ({ type: CLEAR_FILTER });
