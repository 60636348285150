import { Grid, Box, GridSize } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Subcategory, { ISubcategory } from './components/Subcategory';
import CategoryName from './components/CategoryName';

const CenteredGrid = styled(Grid)({ textAlign: 'center' });

interface ICategory {
  'subcategories': ISubcategory[]
  'title': string
  'showCategoryTitle': string
  'gridCountDesktop': GridSize
  'gridCountMobile': GridSize
}

interface IProps {
  'categories': ICategory[]
}

export default ({ categories }: IProps) => (
  <>
    {categories.map((category) => (
      <Box key={category.title} mb={3} mx={1} my={2}>
        {category.showCategoryTitle && <CategoryName text={category.title} />}
        <Box my={2}>
          <CenteredGrid
            container
            spacing={1}
            justify="flex-start"
          >
            {category.subcategories.map((i) => (
              <Subcategory
                key={i.title}
                item={i}
                xs={category.gridCountMobile}
                sm={category.gridCountDesktop}
              />
            ))}
          </CenteredGrid>
        </Box>
      </Box>
    ))}
  </>
);
