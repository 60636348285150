import http from '../../utils/httpClient';
import { DATA_JSON_PATH } from './constants';

const useQuery = () => {
  const timestamp = Date.now();
  const fetchVersion = () => http({ url: `${DATA_JSON_PATH}/version.json?v=${timestamp}` });

  return { fetchVersion };
};

export default useQuery;
