/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { CssBaseline, Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Outlet } from 'react-router-dom';

import useFetch from '../../store/version/hooks/useFetch';

const useStyles = makeStyles((theme) => ({
  base: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 0,
      margin: 0,
    },
    [theme.breakpoints.up('sm')]: { width: '750px !important' },
  },
  wrap: { overflow: 'hidden' },
}));

const PublicLayout = () => {
  const classes = useStyles();

  const { loading, loadVersion } = useFetch();
  useEffect(() => { loadVersion(); }, []);

  if (loading) { return <></>; }

  return (
    <>
      <CssBaseline />
      <Outlet />
    </>
  );
};

export default PublicLayout;
