import {
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    color: '#000 !important',
    [theme.breakpoints.up('sm')]: { padding: '6px' },
  },
  text: {
    [theme.breakpoints.down('xs')]: {
      background: '#1C4AA5 !important',
      color: 'white !important',
      borderRadius: 0,
    },
  },
  buttonLabel: { justifyContent: 'start', textTransform: 'none' },
  downloadLabel: {
    [theme.breakpoints.up('sm')]: {
      borderLeft: '3px solid',
      paddingLeft: theme.spacing(1),
      borderLeftColor: theme.palette.primary.main,
    },
  },
}));

interface IProps {
  'text': string
}

export default ({ text }: IProps) => {
  const classes = useStyles();

  return (
    <Button
      disabled
      fullWidth
      variant="contained"
      className={classes.text}
      classes={{
        root: classes.root,
        label: classes.buttonLabel,
      }}
    >
      <Typography className={classes.downloadLabel}>{`『${text}』`}</Typography>
    </Button>
  );
};
