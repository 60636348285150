/* eslint-disable no-unused-vars */
import { useStateValue } from '../../index';
import { PageLoadingStatus } from '../constants';
import {
  dataLoadingStarted,
  dataLoadedWithSuccess,
  dataLoadedWithError,
} from '../actions';
import useQuery from '../queries';

const useFetch = () => {
  const { fetchVersion } = useQuery();
  const [state, dispatch] = useStateValue();
  const { version } = state;

  const loadVersion = async () => {
    dispatch(dataLoadingStarted());

    try {
      const data = await fetchVersion();
      dispatch(dataLoadedWithSuccess(data.version));
    } catch (err) {
      dispatch(dataLoadedWithError());
    }
  };

  return {
    version,
    loading: version?.pageLoadingState === PageLoadingStatus.LOADING,
    loadingError: version?.pageLoadingState === PageLoadingStatus.LOADED_ERROR,
    loadVersion,
  };
};

export default useFetch;
