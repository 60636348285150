/* eslint-disable no-unused-vars */
export enum PageLoadingStatus {
  INITIAL = 'initial',
  LOADING = 'loading',
  LOADED_SUCCESS = 'loaded_success',
  LOADED_ERROR = 'loaded_error',
}

const CDN_URL = `${process.env.REACT_APP_CDN_URL}`;
export const STRUCTURE_JSON_PATH = `${CDN_URL}/public/data/structure`;
export const PACKAGES_JSON_PATH = `${CDN_URL}/public/data/packages`;
