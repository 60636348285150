/* eslint-disable react/no-danger */
import { Box, Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackToHomeButton from './components/BackToHomeButton';
import Banner from './components/Banner';
import FullwidthImage from '../../../@common/components/FullwidthImage';
import ImageAssets from '../../../../assets/index';
import useLocal from '../../../../store/local/hooks/useLocal';
import useAuth from '../../../../store/auth/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: { marginTop: 0 },
    [theme.breakpoints.up('sm')]: { marginTop: theme.spacing(3) },
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  noImgHeaderBgColor: {
    background: 'teal',
    border: '1px solid teal',
  },
  noImgHeader: {
    background: 'teal',
    border: '1px solid teal',
    color: 'white',
    width: '100%',
    padding: '7px',
    fontWeight: 'bold',
    fontSize: '.9rem',
    borderRadius: '2px',
  },
}));

interface IHeader {
  'description': string
  'downloadInstructionText': string
  'searchByLabelText': string
  'mainImageKey': string
  'category': string
  'pageMobileBanner': string
  'pageDesktopBanner': string
  'hasBanner': boolean
  'showCategoryTitle': boolean
  'showLogout': boolean
}

interface IProp {
  'metadata': IHeader
}

export default ({ metadata }: IProp) => {
  const classes = useStyles();
  const { text } = useLocal();
  const { logoutUser } = useAuth();

  return (
    <>
      <Box className={classes.root}>
        {metadata.mainImageKey !== 'logo' && !metadata.showLogout && (
          <Box mb={3} display={{ xs: 'none', sm: 'block' }}>
            <BackToHomeButton />
          </Box>
        )}

        {metadata.showLogout && (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Box mb={3} display={{ xs: 'none', sm: 'block' }}>
              <BackToHomeButton />
            </Box>
            <Box mb={3} display={{ xs: 'none', sm: 'block' }}>
              <Button onClick={logoutUser} size="small" disableElevation variant="contained">
                {text.logout}
              </Button>
            </Box>
          </Grid>
        )}

        {metadata.mainImageKey && <FullwidthImage alt={metadata.mainImageKey} src={ImageAssets[metadata.mainImageKey]} />}
        {!metadata.mainImageKey && (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.noImgHeaderBgColor}
          >
            <Box display={{ xs: 'none', sm: 'block' }}>
              <Typography className={classes.noImgHeader}>{metadata.category}</Typography>
            </Box>
            <Box display={{ xs: 'block', sm: 'none' }}>
              <Typography className={classes.noImgHeader}>{metadata.category}</Typography>
            </Box>
            <Box mr={1} display={{ xs: 'block', sm: 'none' }}>
              <Button onClick={logoutUser} size="small" disableElevation variant="contained">
                {text.logout}
              </Button>
            </Box>
          </Grid>
        )}
      </Box>

      <Box className={classes.description}>
        <div dangerouslySetInnerHTML={{ __html: metadata.description }} />
      </Box>

      {metadata.hasBanner && (
        <Banner
          mobile={`${process.env.REACT_APP_CDN_URL}/${metadata.pageMobileBanner}`}
          desktop={`${process.env.REACT_APP_CDN_URL}/${metadata.pageDesktopBanner}`}
          url="https://www.booco.jp/"
        />
      )}
    </>
  );
};
