import en from './en.json';
import jp from './jp.json';

export const INITIAL_STATE = {
  en,
  jp,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};
