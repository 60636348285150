/* eslint-disable no-unused-vars */
import { Box, Grid, Button, Snackbar, Container } from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';
import FullpageLoader from '../../@common/components/forms/FullpageLoader';
import FullWidthTextField from '../../@common/components/forms/FullWidthTextField';
import useForm from '../store/hooks/useForm';
import { PageType } from '../../success/constants';
import useLocal from '../../../store/local/hooks/useLocal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    '& .MuiButtonBase-root': { margin: theme.spacing(1) },
  },
  default: {
    textTransform: 'none',
    color: theme.palette.text.primary,
    background: theme.palette.background.default,
    outline: 'none',
    '&:hover': { background: theme.palette.background.paper },
  },
  primary: {
    textTransform: 'none',
    color: 'white',
    background: theme.palette.primary.main,
    outline: 'none',
    '&:hover': { background: '#205f82' },
    borderColor: blue[500],
  },
}));
export default () => {
  const { text } = useLocal();
  const navigate = useNavigate();
  const { token } = useParams();
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPassError, setConfirmPassError] = useState(false);
  const [passError, setPassError] = useState(false);
  const { submitting, submittedWithSuccess, submittedWithError, resetFormState, submitForm } = useForm();

  const handleSubmit = (e:any) => {
    e.preventDefault();
    setPassError(false);
    setConfirmPassError(false);
    let errors = 0;

    if (password === '') {
      setPassError(true);
      errors += 1;
    }

    if (confirmPassword !== password) {
      setConfirmPassError(true);
      errors += 1;
    }

    if (errors === 0) {
      submitForm(password, token);
    }
  };

  useEffect(() => {
    if (submittedWithSuccess) {
      resetFormState();
      navigate('/success', { replace: true, state: { type: PageType.PASSWORD_RESET } });
    }
  }, [submittedWithSuccess]);

  return (
    <>
      <Container maxWidth="xs">
        <Box
          boxShadow={3}
          bgcolor="background.paper"
          marginTop={20}
          m={1}
          p={1}
        >

          <form className={classes.root} onSubmit={handleSubmit} noValidate>

            <FullWidthTextField
              onChange={(value:any) => { setPassword(value); }}
              label={text.reset_password.form.newPasswordLabel}
              type="password"
              password
              error={passError}
              helperText={text.reset_password.form.passwordError}
            />
            <FullWidthTextField
              onChange={(value:any) => setConfirmPassword(value)}
              label={text.reset_password.form.confirmPasswordLabel}
              type="password"
              error={confirmPassError}
              password
              helperText={text.reset_password.form.confirmPasswordError}
            />

            <Box height={10} />

            <Button type="submit" variant="contained" className={classes.primary}>
              {text.buttons.reset_password}
            </Button>

          </form>

        </Box>
      </Container>
      <FullpageLoader open={submitting} />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={submittedWithError}
        autoHideDuration={3000}
        onClose={resetFormState}
      >
        <Alert onClose={() => resetFormState} severity="error">
          Something went wrong, please try again later.
        </Alert>
      </Snackbar>

    </>
  );
};
