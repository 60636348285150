import { useState } from 'react';
import { Box, Paper, Button, Typography, Link } from '@material-ui/core/';
import Alert from '@material-ui/lab/Alert';
import SecurityIcon from '@material-ui/icons/Security';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import FullWidthTextField from '../@common/components/FullWidthTextField';
import LoadingButton from '../@common/components/LoadingButton';
import useAuth from '../../store/auth/hooks/useAuth';
import useLocal from '../../store/local/hooks/useLocal';

const useStyles = makeStyles((theme) => ({
  alert: {
    color: theme.palette.primary.light,
    background: theme.palette.primary.main,
    borderRadius: 0,
  },
  resetLink: {
    display: 'block',
    paddingTop: 16,
    textAlign: 'center',
  },
}));

export default () => {
  const { text } = useLocal();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { isLoading, error, authUser } = useAuth();

  return (
    <>
      <Paper elevation={3}>
        <Alert
          icon={(
            <SecurityIcon
              style={{ color: '#fff' }}
              fontSize="inherit"
            />
          )}
          className={classes.alert}
        >
          {text.login.buttons.login}
        </Alert>

        <form style={{ padding: 24 }} onSubmit={() => authUser(email, password)}>
          {error && <Alert style={{ marginBottom: 12 }} severity="error">{text.login.error}</Alert>}

          <FullWidthTextField
            autoFocus
            value={email}
            title={text.login.emailLabel}
            onSave={(value: any) => setEmail(value)}
            hasError={false}
            errorMessage=""
          />

          <FullWidthTextField
            value={password}
            title={text.login.passwordLabel}
            type="password"
            onSave={(value: any) => setPassword(value)}
            hasError={false}
            errorMessage=""
            autoComplete="new-password"
          />

          <Box height={10} />

          {!isLoading && (
            <Button
              type="submit"
              disabled={password.length === 0}
              onClick={() => authUser(email, password)}
              variant="contained"
              color="primary"
              fullWidth
            >
              {text.login.buttons.login}
            </Button>
          )}

          {isLoading && <LoadingButton />}

          <Link className={classes.resetLink} component={RouterLink} to="/register">
            <Typography variant="caption" gutterBottom>
              {text.login.buttons.signup}
            </Typography>
          </Link>

          <Link className={classes.resetLink} component={RouterLink} to="/request_reset_password">
            <Typography variant="caption" gutterBottom>
              {text.login.buttons.forgot_pass}
            </Typography>
          </Link>
        </form>
      </Paper>
    </>
  );
};
