import {
  Grid,
  Button,
} from '@material-ui/core';

interface IProps {
  'scrollToTop': Function
}

export default ({ scrollToTop }: IProps) => (
  <>
    <Grid container justify="flex-end">
      <Button color="secondary" onClick={() => scrollToTop()}>▲TOPに戻る</Button>
    </Grid>
  </>
);
