/* eslint-disable no-unused-vars */
import { PageLoadingStatus } from './constants';

import {
  BOOK_LOADING,
  BOOK_LOADED_SUCCESS,
  BOOK_LOADED_ERROR,

  FILTER_BOOKS,
  CLEAR_FILTER,
} from './actions';

export const INITIAL_STATE = {
  structure: null,
  packages: null,
  filters: [],
  searchResult: [],
  pageLoadingState: PageLoadingStatus.LOADING,
  bookLoadingState: PageLoadingStatus.LOADING,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case BOOK_LOADING:
      return {
        ...state,
        structure: null,
        packages: null,
        bookLoadingState: PageLoadingStatus.LOADING,
      };
    case BOOK_LOADED_SUCCESS:
      return {
        ...state,
        structure: action.payload.structure,
        packages: action.payload.packages,
        filters: [],
        searchResult: [],
        bookLoadingState: PageLoadingStatus.LOADED_SUCCESS,
      };
    case BOOK_LOADED_ERROR:
      return {
        ...state,
        structure: null,
        packages: null,
        filters: [],
        searchResult: [],
        bookLoadingState: PageLoadingStatus.LOADED_ERROR,
      };
    case FILTER_BOOKS:
      return {
        ...state,
        searchResult: action.payload.searchResults,
        filters: [action.payload.filter],
      };
    case CLEAR_FILTER:
      return {
        ...state,
        searchResult: [],
        filters: [],
      };
    default:
      return state;
  }
};
