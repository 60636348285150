import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dark: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },
  light: {
    color: 'teal',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

interface IProp {
  open: boolean
  dark?: boolean
}

export default ({ open, dark = true }: IProp) => {
  const classes = useStyles();
  return (
    <Backdrop
      invisible={!dark}
      className={dark ? classes.dark : classes.light}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
