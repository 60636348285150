import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { PageType } from '../success/constants';

export default () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/success', { replace: true, state: { type: PageType.EMAIL_CONFIRMED } });
  });
  return <>Email Confirmed</>;
};
