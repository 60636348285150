const axios = require('axios');

interface IProp {
  url: string
  method?: string
  accessToken?: string
  body?: any
}

export default ({ url, method, accessToken = '', body }: IProp) => {
  const params: any = {};
  params.url = url;
  params.method = method ?? 'GET';
  params.data = method === 'POST' || method === 'PUT' ? JSON.stringify(body) : null;
  params.headers = {};
  params.headers['Content-Type'] = 'application/json';
  params.headers.Authorization = accessToken;

  return axios(params).then((res: any) => res.data);
};
