/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export enum FormStatus {
  INACTIVE = 'inactive',
  SUBMITTING = 'submitting',
  SUBMITTED_SUCCESS = 'submitted_success',
  SUBMITTED_ERROR = 'submitted_error',
}

export enum PageLoadingStatus {
  INITIAL = 'initial',
  LOADING = 'loading',
  LOADED_SUCCESS = 'loaded_success',
  LOADED_ERROR = 'loaded_error',
}

const CDN_URL = `${process.env.REACT_APP_CDN_URL}`;
const BASE_API_URL = `${process.env.REACT_APP_API_URL}`;

export const USERS_API = `${BASE_API_URL}/users`;
export const ROLES_JSON_PATH = `${CDN_URL}/public/roles.json`;
