import http from '../../utils/httpClient';
import { STRUCTURE_JSON_PATH, PACKAGES_JSON_PATH } from './constants';

interface IStructure {
  role: string
  pageName: string
  metadata: {
    category: string
    description: string
    downloadInstructionText: string
    mainImageKey: string
    pageDesktopBanner: string
    pageMobileBanner: string
    searchByLabelText: string
    hasBanner: boolean
    needAuth: boolean
    showLogout: boolean
  }
  categories: {
    title: string
    gridCountDesktop: number
    gridCountMobile: number
    order: number
    showCategoryTitle: boolean
    subcategories: {
      buttonKey: string
      headerImageKey: string
      title: string
      isLink: boolean
      sortPosition: number
      notes: string | null
      pageName: string | null
    }[]
  }[]
}

interface IPackage {
  pageName: string
  materials: {
    bookImageUrl: string
    bookTitle: string
    categoryTitle: string
    downloadNotes: string
    headerImageKey: string
    headerNotes: string | null
    metadata: string | null
    needAuth: number
    pageName: string
    password: string | null
    passwordId: string | null
    productId: string
    subProductId: string | null
    subcategoryTitle: string
  }[]
}

const useQuery = (version: number, pageName: string) => {
  const fetchStructure = (): IStructure => http({ url: `${STRUCTURE_JSON_PATH}/${pageName}.json?v=${version}` });
  const fetchPackages = (): IPackage => http({ url: `${PACKAGES_JSON_PATH}/${pageName}.json?v=${version}` });
  const fetchBooks = () => Promise.all([fetchStructure(), fetchPackages()]);

  return { fetchBooks };
};

export default useQuery;
