/* eslint-disable no-plusplus */
import { useLayoutEffect, useEffect, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';

import Book from './Book';
import Header from './SearchHeaderName';
import ScrollToTop from './ScrollToTop';

const STATE_HAS_NEW_DATA = 'HAS_NEW_DATA';
const STATE_WILL_LOAD_DATA = 'WILL_LOAD_DATA';
const STATE_HAS_LOADED = 'HAS_LOADED';
const ITEM_TYPE_HEADER = 'HEADER';

const CenteredGrid = styled(Grid)({ textAlign: 'left' });

interface ISubcategory {
  'productId': string
  'subProductId': string | null
  'type': string
  'title': string
  'imageUrl': string
  'needAuth': boolean
  'password': string | null
  'passwordId': string | null
  'headerNotes': string | null
  'downloadNotes': string | null
}

interface IFilter {
  'filterType': string
}

interface IProps {
  'matchedBooks': ISubcategory[]
  'filters': IFilter[]
}

export default ({ matchedBooks, filters }: IProps) => {
  const BOOKS_PER_PAGE = 10;

  const [pageState, setPageState] = useState<string>(STATE_HAS_NEW_DATA);

  const [loading, setLoading] = useState<boolean>(true);

  const [books, setBooks] = useState<any>([]);

  const [page, setPage] = useState<number>(0);

  const [headerRunningTotal, setHeaderRunningTotal] = useState<number>(0);

  const [breakPoint, setBreakPoint] = useState<number>(0);

  const shouldShowScrollToTop = (
    next: number,
  ) => matchedBooks[next]?.type === ITEM_TYPE_HEADER || !matchedBooks[next];

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handleScroll = () => {
    const moreAnchor = document.getElementById('more-anchor');
    const bottomAnchorLoc = moreAnchor?.getBoundingClientRect().bottom;
    const shouldPaginate = bottomAnchorLoc! > window.innerHeight && loading === false;

    if (shouldPaginate) {
      setPageState(STATE_WILL_LOAD_DATA);
    }
  };

  useEffect(() => {
    const section = filters[0]?.filterType === 'subcategory'
      ? 'book-section' : 'searchbox-section';

    if (matchedBooks.length > 0) {
      document.getElementById(section)?.scrollIntoView();
    }

    setPage(0);
    setBooks([]);
    setHeaderRunningTotal(0);
    setBreakPoint(0);
    setPageState(STATE_WILL_LOAD_DATA);
  }, [matchedBooks]);

  useLayoutEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  });

  const runningBookCount = (page * BOOKS_PER_PAGE) + headerRunningTotal;

  if (pageState === STATE_WILL_LOAD_DATA) {
    setLoading(true);
    setPageState(STATE_HAS_NEW_DATA);
  } else if (pageState === STATE_HAS_NEW_DATA) {
    const nextPaginatedBooks = [...books];
    const nextBreakPoint = runningBookCount + BOOKS_PER_PAGE;

    for (let i = breakPoint; i < matchedBooks.length; i++) {
      const item = matchedBooks[i];

      if (item.type === ITEM_TYPE_HEADER) {
        setHeaderRunningTotal(headerRunningTotal + 1);
        nextPaginatedBooks.push(<Header key={`header-${item.title}-${i}`} item={item} />);
      } else {
        nextPaginatedBooks.push(<Book key={`book-${item.title}-${i}`} item={item} />);
      }

      if (shouldShowScrollToTop(i + 1)) {
        nextPaginatedBooks.push(<ScrollToTop key={`${item.title}-${i}`} scrollToTop={scrollToTop} />);
      }

      if (i === nextBreakPoint) {
        setBreakPoint(nextBreakPoint + 1);
        break;
      }
    }

    setLoading(false);
    setPage(page + 1);
    setBooks([...nextPaginatedBooks]);
    setPageState(STATE_HAS_LOADED);
  }

  const atEndOfList = runningBookCount < matchedBooks.length;

  return (
    <Box my={4} id="book-section">
      <CenteredGrid container spacing={1}>
        {books}
        {atEndOfList && <div id="more-anchor" />}
      </CenteredGrid>
    </Box>
  );
};
