import { useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import BookTitle from './components/BookTitle';
import BookDescription from './components/BookDescription';
import PasswordModal from './components/PasswordModal';
import ImageAssets from '../../../../assets/index';

const useStyles = makeStyles((theme) => ({
  root: { [theme.breakpoints.up('sm')]: { marginTop: theme.spacing(4) } },
  button: {
    border: 'none',
    background: 'none',
    outline: 'none',
    '&:hover': { cursor: 'pointer' },
    [theme.breakpoints.up('sm')]: { '&:hover': { opacity: '0.5' } },
  },
}));

interface IProps {
  'title': string
  'description': string
  'materials': any
  'jp': boolean
}

export default ({ title, description, materials, jp }: IProps) => {
  const material = materials.find((m: any) => (jp ? m.subcategoryTitle === 'Japanese' : m.subcategoryTitle === 'English'));
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onValidPassword = () => navigate(`/dl/${material.productId}/${material.passwordId}`);

  return (
    <>
      <Box className={classes.root}>
        <BookTitle text={title} />
        <BookDescription text={description} />
        <Box style={{ margin: '8px 0', display: 'flex', justifyContent: 'center' }}>
          <button
            className={classes.button}
            type="button"
            onClick={handleClickOpen}
          >
            <img src={jp ? ImageAssets.pageJpOnlineBtnJap : ImageAssets.pageJpOnlineBtnEng} alt="" />
          </button>
        </Box>
      </Box>
      <PasswordModal
        open={open}
        bookPassword={material.password}
        openProductDetailsPage={onValidPassword}
        handleClose={handleClose}
      />
    </>
  );
};
