import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#007297',
      dark: '#055d7a',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#0041a5',
      dark: '#ba000d',
      contrastText: '#000',
    },
    text: {
      primary: '#000',
      secondary: '#333',
    },
  },
  shape: { borderRadius: 2 },
});

export default theme;
