import Material, { IMaterial } from './components/Material';

interface IProps {
  'materials': IMaterial[]
}

export default ({ materials }: IProps) => (
  <>
    {materials.map((material) => <Material key={material.title} material={material} />)}
  </>
);
