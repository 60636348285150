import { Box, Link } from '@material-ui/core';
import FullwidthImage from '../../../../@common/components/FullwidthImage';

interface IProp {
  'mobile': string
  'desktop': string
  'url': string
}

export default ({ mobile, desktop, url }: IProp) => (
  <>
    <Box display={{ xs: 'block', sm: 'none' }}>
      <Link href={url}>
        <FullwidthImage alt="https://www.booco.jp/" src={mobile} />
      </Link>
    </Box>

    <Box display={{ xs: 'none', sm: 'block' }}>
      <Link href={url}>
        <FullwidthImage alt="https://www.booco.jp/" src={desktop} />
      </Link>
    </Box>
  </>
);
