import { useLocation } from 'react-router-dom';
import JpnOnlinePackage from './sections/japan_online';
import PageNotFound from '../@common/components/PageNotFound';
import useFetch from '../../store/book/hooks/useFetch';
import useAuth from '../../store/auth/hooks/useAuth';
import FullpageLoader from '../@common/components/forms/FullpageLoader';

export default () => {
  const { pathname } = useLocation();
  const pageName = pathname.substring(4, 13);
  const { auth: { roles }, verifyUser } = useAuth();
  const booksParams = { roles, pageName: pageName || 'index', verifyUser };
  const { books, loading, error } = useFetch(booksParams);

  if (loading) { return <FullpageLoader dark={false} open={loading} />; }
  if (error) { return <PageNotFound />; }

  return (
    <>
      <JpnOnlinePackage
        title={books.structure.metadata.titleJap}
        description={books.structure.metadata.bodyHtmlJap}
        materials={books.packages.materials}
        jp
      />
      <JpnOnlinePackage
        title={books.structure.metadata.titleEn}
        description={books.structure.metadata.bodyHtmlEn}
        materials={books.packages.materials}
        jp={false}
      />
    </>
  );
};
