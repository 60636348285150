/* eslint-disable react/no-danger */
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

interface IProps {
  'text': string
}

export default ({ text }: IProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} my={2}>
      <div className="j14" dangerouslySetInnerHTML={{ __html: text }} />
    </Box>
  );
};
