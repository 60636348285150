import { Grid, Button, Box, Typography, Divider } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: { height: '100vh' },
  paper: { padding: '16px' },
  listStyle: { listStyleType: 'circle' },
  toListButton: { color: `${theme.palette.text.primary} !important` },
}));

export default () => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.container}
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Alert severity="warning">
        <AlertTitle>お探しのページは見つかりません。</AlertTitle>
        <Divider style={{ background: 'rgb(244 226 199)', marginBottom: 16 }} />
        <Typography component="p" variant="caption">ただいまご参照のリクエストのあったファイルは現在、下記の理由によりご参照いただけません。</Typography>
        <Typography component="p" variant="caption">・ リンク元のファイルのリンク指定が間違っている</Typography>
        <Typography component="p" variant="caption">・ サービスが中止された</Typography>
        <Typography component="p" variant="caption">・ サービスのURLが変更された</Typography>
        <Typography component="p" variant="caption">・ JUMPコード欄に間違ったコードが入力された</Typography>
        <Box mt={2} />
        <Typography component="p" variant="caption">お手数ですが、アルクウェブサイトのトップページより再入場願います。</Typography>
        <Box mt={2} style={{ textAlign: 'center' }}>
          <Button
            className={classes.toListButton}
            component={RouterLink}
            size="small"
            disableElevation
            variant="contained"
            to="/"
            startIcon={<ArrowBackIcon />}
          >
            ダウンロードセンタートップへ
          </Button>
        </Box>
      </Alert>
    </Grid>
  );
};
