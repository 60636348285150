/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Box, Snackbar } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import Alert from '@material-ui/lab/Alert';
import useLocal from '../../../../store/local/hooks/useLocal';
import TextLabelField from './components/TextLabelField';
import RolesCheckList from './role';
import useRegisterForm from '../../store/hooks/useRegisterForm';
import FullpageLoader from '../../../@common/components/forms/FullpageLoader';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(5),
    '& .MuiButtonBase-root': { margin: theme.spacing(1) },
  },
  default: {
    textTransform: 'none',
    color: `${theme.palette.text.primary} !important`,
    background: theme.palette.background.default,
    outline: 'none',
    '&:hover': { background: theme.palette.background.paper },
  },
  primary: {
    textTransform: 'none',
    color: 'white',
    background: theme.palette.primary.main,
    outline: 'none',
    '&:hover': { background: '#205f82' },
    borderColor: blue[500],
  },
}));

export default () => {
  const { text } = useLocal();
  const navigate = useNavigate();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [confirmPassError, setConfirmPassError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [roles, setRoles] = useState('');
  const [rolesError, setRolesError] = useState(false);
  const { submitting, submittedWithSuccess, submittedWithError, resetFormState, submitForm } = useRegisterForm();
  const validateEmail = (val:string) => new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i).test(val);
  const handleSubmit = (e:any) => {
    e.preventDefault();
    setPassError(false);
    setConfirmPassError(false);
    setEmailError(false);
    let errors = 0;

    if (roles.length === 0) {
      setRolesError(true);
      errors += 1;
    }
    if (confirmPassword !== password) {
      setConfirmPassError(true);
      errors += 1;
    }
    if (password === '') {
      setPassError(true);
      errors += 1;
    }

    if (!validateEmail(email)) {
      setEmailError(true);
      errors += 1;
    }

    if (errors === 0) {
      submitForm(email, password, roles);
    }
  };

  const handleChange = (selected:any) => {
    if (selected.length === 0) {
      setRolesError(true);
    } else {
      setRolesError(false);
    }
    setRoles(selected);
  };

  useEffect(() => {
    if (submittedWithSuccess) {
      resetFormState();
      navigate('/success', { replace: true });
    }
  }, [submittedWithSuccess]);

  return (
    <>
      <Box
        boxShadow={3}
        bgcolor="background.paper"
        marginTop={5}
        m={1}
        p={1}
      >
        <form className={classes.root} onSubmit={handleSubmit} noValidate>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
            spacing={3}
          >
            <TextLabelField
              onChange={(value:any) => setEmail(value)}
              label={text.register.form.emailLabel}
              type="email"
              autoFocus
              error={emailError}
              helperText={text.register.form.emailError}
            />
            <TextLabelField
              onChange={(value:any) => setPassword(value)}
              label={text.register.form.passwordLabel}
              type="password"
              password
              error={passError}
              helperText={text.register.form.passwordError}
            />
            <TextLabelField
              onChange={(value:any) => setConfirmPassword(value)}
              label={text.register.form.confirmPasswordLabel}
              type="password"
              error={confirmPassError}
              password
              helperText={text.register.form.confirmPasswordError}
            />
            <RolesCheckList
              title={text.register.form.rolesLabel}
              changeHandler={(selected: any) => handleChange(selected)}
              hasError={rolesError}
              errorMessage={text.register.form.rolesError}
            />
          </Grid>
          <Box height={30} />

          <div>
            <Button variant="contained" className={classes.default} component={RouterLink} to="/login">
              {text.buttons.cancel}
            </Button>
            <Button type="submit" variant="contained" className={classes.primary}>
              {text.buttons.register}
            </Button>
          </div>
        </form>
      </Box>
      <FullpageLoader open={submitting} />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={submittedWithError}
        autoHideDuration={3000}
        onClose={resetFormState}
      >
        <Alert onClose={() => resetFormState} severity="error">
          Something went wrong, please try again later.
        </Alert>
      </Snackbar>

    </>
  );
};
