import {
  Box,
  Grid,
  Typography,
  Paper,
  Divider,
} from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { makeStyles } from '@material-ui/core/styles';
import useSearch from '../../../../store/book/hooks/useSearch';
import SearchBox from './components/SearchBox';
import SearchResult from './components/SearchResult';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(1),
    background: '#efefef',
  },
  searchBoxContainer: {
    [theme.breakpoints.down('xs')]: { padding: 0 },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(18),
      paddingRight: theme.spacing(18),
    },
  },
  searchLabel: {
    borderLeft: '3px solid #888',
    paddingLeft: theme.spacing(1),
  },
  downloadLabel: {
    borderLeft: '3px solid',
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderLeftColor: theme.palette.primary.main,
  },
  downloadDivider: {
    height: '0',
    borderBottomWidth: '0',
    borderTopWidth: '2px',
    borderStyle: 'dotted',
    marginBottom: theme.spacing(2),
    borderColor: theme.palette.primary.main,
  },
  instructionIcon: { maxWidth: '20px' },
}));

interface IHeader {
  'description': string
  'downloadInstructionText': string
  'searchByLabelText': string
  'mainImageKey': string
  'hasBanner': boolean
}

interface IProp {
  'metadata': IHeader
}

export default ({ metadata }: IProp) => {
  const classes = useStyles();
  const { books, search } = useSearch();

  return (
    <>
      <Box className={classes.root} mb={5} my={3} pt={1} id="searchbox-section">
        <Typography variant="subtitle2" className={classes.downloadLabel}>ダウンロードコンテンツ</Typography>
        <Divider className={classes.downloadDivider} />

        <Grid container justify="flex-start">
          <Grid item xs={1} className={classes.instructionIcon}>
            <DescriptionOutlinedIcon fontSize="small" color="primary" />
          </Grid>
          <Grid item sm={11} xs={11}>
            <Typography
              variant="subtitle2"
              style={{ marginLeft: '5px' }}
            >
              {metadata.downloadInstructionText}
            </Typography>
          </Grid>
        </Grid>

        <Box mt={3} className={classes.searchBoxContainer}>
          <Paper className={classes.paper} elevation={3} variant="outlined">
            <Typography className={classes.searchLabel} variant="h6">{metadata.searchByLabelText}</Typography>
            <SearchBox pageName={books.structure.pageName} onSearch={search} />
          </Paper>
        </Box>
      </Box>
      <SearchResult matchedBooks={books.searchResult} filters={books.filters} />
    </>
  );
};
