import { Grid, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';
import BookImage from '../../../../@common/components/BookImage';

const useStyles = makeStyles((theme) => ({
  bookContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
  },
}));

export interface IBook {
  'title': string,
  'imageUrl': string
}

interface IProps {
  'item': IBook
}

export default ({ item }: IProps) => {
  const classes = useStyles();

  const BookImageView = <BookImage title={item.title} url={item.imageUrl} width={144} height={200} />;
  const ImagePlaceholder = <Avatar style={{ height: '200px', width: '144px' }} variant="square" alt={item.title}><InsertPhotoOutlinedIcon style={{ fontSize: 80 }} /></Avatar>;

  return (
    <Grid className={classes.bookContainer} item xs={12} sm={5}>
      {item.imageUrl ? BookImageView : ImagePlaceholder}
    </Grid>
  );
};
