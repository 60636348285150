/* eslint-disable no-restricted-globals */
import { useLayoutEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import HeaderSection from './sections/header';
import SearchSection from './sections/search';
import CategorySection from './sections/category';
import PageNotFound from '../@common/components/PageNotFound';
import useFetch from '../../store/book/hooks/useFetch';
import useAuth from '../../store/auth/hooks/useAuth';
import FullpageLoader from '../@common/components/forms/FullpageLoader';

export default () => {
  const { pageName } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { auth: { roles }, verifyUser } = useAuth();
  const booksParams = { roles, pageName: pageName || 'index', verifyUser };
  const { books, loading, error } = useFetch(booksParams);

  useLayoutEffect(() => {
    const SP_SCHEME = `alcoapp://webview?URL=${process.env.REACT_APP_PORTAL_URL}/${pageName}`;
    const userAgent = navigator.userAgent.toLowerCase();
    const isDeepLink = pathname.substring(0, 6) === '/alco/' || pathname.substring(0, 5) === '/alco';
    const isMobileUserAgent = userAgent.search(/iphone|ipad|ipod|android|opera.*mini|windows.*phone/) > -1 || userAgent.indexOf('macintosh') > -1;
    const hasTouchEndHandler = 'ontouchend' in document;
    const shouldOpenApp = isMobileUserAgent && hasTouchEndHandler && isDeepLink;

    if (shouldOpenApp) {
      setTimeout(() => { location.href = SP_SCHEME; }, 500);
    } else if (isDeepLink) {
      navigate(`/${pageName}`);
    }
  });

  if (loading) { return <FullpageLoader dark={false} open={loading} />; }
  if (error) { return <PageNotFound />; }

  return (
    <>
      <HeaderSection metadata={books.structure.metadata} />
      <SearchSection metadata={books.structure.metadata} />
      <CategorySection categories={books.structure.categories} />
    </>
  );
};
