/* eslint-disable no-unused-vars */
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImageAssets from '../../../../../assets/index';

const useStyles = makeStyles((theme) => ({
  noImgHeader: {
    border: '1px solid teal',
    background: 'teal',
    color: 'white',
    width: '100%',
    padding: '2px 5px',
    fontWeight: 'bold',
    fontSize: '.8rem',
    borderRadius: '4px',
    letterSpacing: '.3rem',
  },
  img: { width: '100%' },
  headerNotes: { margin: '10px 0', fontSize: '.8rem' },
}));

interface IHeader {
  'title': string
  'imageUrl': string
  'headerNotes': string | null
}

interface IProps {
  'item': IHeader
}

export default ({ item }: IProps) => {
  const classes = useStyles();

  return (
    <Grid key={item.title} item xs={12} sm={12}>
      {item.imageUrl && <img className={classes.img} src={ImageAssets[item.imageUrl]} alt={item.title} />}
      {!item.imageUrl && <Typography className={classes.noImgHeader}>{item.title}</Typography>}
      {item.headerNotes && <Typography className={classes.headerNotes}>{item.headerNotes}</Typography>}
    </Grid>
  );
};
