import http from '../../utils/httpClient';
import { MATERIAL_JSON_PATH, REF_JSON_PATH } from './constants';

const useQuery = () => {
  const fetchDetails = (version: number, id: string) => http({ url: `${MATERIAL_JSON_PATH}/${id}.json?v=${version}` });
  const fetchRef = (version: number, id: string) => http({ url: `${REF_JSON_PATH}/${id}.json?v=${version}` });

  return { fetchDetails, fetchRef };
};

export default useQuery;
